/* eslint-disable jsx-a11y/img-redundant-alt */
// TrainerCard.tsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import './TrainerCard.css';

interface Service {
  service_name: string;
  next_availability?: string;
}

interface TrainerCardProps {
  slug: string;
  profilePhotoUrl: string;
  name: string;
  ville: string;
  description?: string;
  isProfessional: boolean;
  className?: string; // Ajouter la prop className optionnelle
  onHover?: () => void;
  onLeave?: () => void;
  isHighlighted?: boolean;
  ratingContent?: React.ReactNode;
  services?: Service[]; // Add services array
}

const TrainerCard: React.FC<TrainerCardProps> = ({
  slug,
  profilePhotoUrl,
  name,
  ville,
  description,
  isProfessional, // Utilisation de la propriété
  className, // Récupérer la prop className
  onHover,
  onLeave,
  isHighlighted,
  ratingContent,
  services = [],
}) => {
  const navigate = useNavigate();
  dayjs.locale('fr'); // Set French locale

  const handleCardClick = () => {
    navigate(`/pro/${slug}`);
  };

  const formatDate = (dateStr?: string) => {
    if (!dateStr) return null;
    return dayjs(dateStr).format('dddd D MMMM');
  };

  return (
    <div
      className={`trainer-card ${className}`}
      onClick={handleCardClick}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      role="button"
      tabIndex={0}
    >
      <div className="trainer-card-image-wrapper">
        <img src={profilePhotoUrl} alt={`Photo de ${name}`} className="trainer-card-image" />
        {isProfessional && <span className="trainer-badge">Pro</span>}
      </div>
      <div className="trainer-card-content">
        <div className="trainer-card-header">
          <h2 className="trainer-card-title">{name}</h2>
          <p className="trainer-card-ville">{ville}</p>
        </div>
        <div className="trainer-card-services">
          {services.slice(0, 2).map((service, index) => (
            <div key={index} className="trainer-card-service">
              <div className="service-content">
                <div className="service-header">
                  <span className="service-name">{service.service_name}</span>
                  <span className="service-icon">→</span>
                </div>
                {service.next_availability && (
                  <div className="service-availability">
                    <span className="availability-dot"></span>
                    <span className="availability-text">
                      {formatDate(service.next_availability)}
                    </span>
                  </div>
                )}
              </div>
            </div>
          ))}
          {services.length === 0 && (
            <div className="trainer-card-service placeholder full-width">
              <div className="service-content">
                <div className="service-header">
                  <span className="service-name">Aucun service disponible</span>
                </div>
              </div>
            </div>
          )}
          {services.length === 1 && (
            <div className="trainer-card-service placeholder">
              <div className="service-content">
                <div className="service-header">
                  <span className="service-name">Aucun autre service</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrainerCard;
