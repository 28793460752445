// src/pages/AboutPage/AboutPage.tsx
import React from 'react';
import './About.css';
// Vous pouvez ajouter votre icône minimaliste ici, par exemple un logo ou une icône de chien
// import dogIcon from '../../assets/dogIcon.svg';

const AboutPage: React.FC = () => {
  return (
    <div className="about-wrapper">

      {/* Section 1: À propos de Rintintin */}
      <section className="section-card">
        <div className="section-header">
          {/* Si vous avez un dogIcon, dé-commentez cette ligne :
          <img src={dogIcon} alt="Icon" className="section-icon" /> */}
          <h1>À propos de Rintintin</h1>
        </div>
        <p>
          Bienvenue sur Rintintin, la plateforme dédiée à vos meilleurs amis. Nous sommes là pour vous accompagner dans le bien-être et l’épanouissement de votre compagnon.
        </p>
        <p>
          Notre mission est de simplifier la recherche d’éducateurs canins qualifiés, en vous proposant une interface intuitive et des services adaptés à vos besoins.
        </p>
        <p>
          Nous travaillons exclusivement avec des éducateurs canins professionnels, sélectionnés pour leur expertise et leur passion, afin de garantir des séances efficaces et personnalisées pour chaque chien.
        </p>
      </section>

      {/* Section 2: Notre mission */}
      <section className="section-card">
        <div className="section-header">
          {/* <img src={dogIcon} alt="Icon" className="section-icon" /> */}
          <h2>Notre mission : Simplifier la vie des propriétaires et des professionnels</h2>
        </div>
        <p>
          Rintintin a été conçu pour répondre à deux besoins essentiels :
        </p>
        <ul>
          <li>
            <strong>Pour les propriétaires d’animaux :</strong> Trouver un professionnel qualifié et bienveillant ne devrait pas être une épreuve. Avec Rintintin Booking, nous vous aidons à localiser facilement des éducateurs canins près de chez vous, sélectionnés pour leur expertise et leur engagement envers des méthodes respectueuses.
          </li>
          <br />
          <li>
            <strong>Pour les professionnels du secteur animalier :</strong> Nous savons combien gérer une activité peut être chronophage et complexe. Rintintin Pro est notre solution de gestion complète : réservation, planning, gestion client, facturation... tout en un pour vous permettre de vous concentrer sur ce qui compte vraiment.
          </li>
        </ul>
      </section>

      {/* Section 3: Vision */}
      <section className="section-card">
        <div className="section-header">
          {/* <img src={dogIcon} alt="Icon" className="section-icon" /> */}
          <h2>Une vision ambitieuse : Devenir l’indispensable du bien-être animal</h2>
        </div>
        <p>
          Notre rêve ? Faire de Rintintin une plateforme incontournable pour tous les amoureux des animaux. Voici notre vision pour l’avenir :
        </p>
        <ul>
          <li>
            <strong>Une plateforme complète :</strong> Aujourd’hui, Rintintin référence des éducateurs canins. Demain, nous ambitionnons d’inclure tous les métiers du secteur animalier : pensions, pet sitters, toiletteurs, animaleries... une véritable porte d’entrée vers tous les services nécessaires pour le bien-être de vos compagnons.
          </li>
          <br />
          <li>
            <strong>Un centre d’expertise :</strong> Vous avez une question sur l’éducation, l’alimentation ou la santé de votre animal ? Nous voulons que votre premier réflexe soit Rintintin. Articles, conseils, newsletter… notre plateforme deviendra une mine d’informations fiables et accessibles.
          </li>
          <br />
          <li>
            <strong>L’assistant incontournable des professionnels :</strong> Avec Rintintin Pro, nous imaginons un outil qui ne se contente pas de gérer votre planning, mais qui vous aide à développer votre activité : communication, marketing, fidélisation... tout ce qu’il faut pour accompagner les professionnels du secteur dans leur succès.
          </li>
        </ul>
      </section>

      {/* Section 4: Valeurs */}
      <section className="section-card">
        <div className="section-header">
          {/* <img src={dogIcon} alt="Icon" className="section-icon" /> */}
          <h2>Nos valeurs : Bienveillance et respect avant tout</h2>
        </div>
        <p>
          Chez Rintintin, nous avons une conviction : chaque animal mérite un accompagnement basé sur la bienveillance et le respect.
        </p>
        <p>
          Cette éthique guide chacun de nos choix. Parce que le bien-être des animaux, c’est bien plus qu’une mission : c’est une responsabilité.
        </p>
      </section>

      {/* Section 5: Conclusion & Remerciements */}
      <section className="section-card">
        <div className="section-header">
          {/* <img src={dogIcon} alt="Icon" className="section-icon" /> */}
          <h2>L’aventure continue, et vous en faites partie</h2>
        </div>
        <p>
          Ce projet, c’est plus qu’une plateforme. C’est une communauté qui partage une vision commune : un monde où chaque animal bénéficie de l’attention, des services et de l’amour qu’il mérite.
        </p>
        <p>
          Alors que vous soyez un professionnel cherchant à simplifier sa gestion, ou un propriétaire à la recherche du meilleur pour son compagnon, Rintintin est là pour vous accompagner.
        </p>
        <p>
          Et ce n’est que le début.
        </p>
        <p style={{ fontStyle: 'italic', textAlign: 'center', marginTop: '20px' }}>
          Merci de faire partie de cette aventure.<br />
          Jean & Aksel
        </p>
      </section>
    </div>
  );
};

export default AboutPage;
