import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './LoginPage.css';
import Divider from '@mui/material/Divider';
import { Button } from 'evergreen-ui';
// import SignInWithGoogle from '../../components/SignInWithGoogle/SignInWithGoogle';

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  // --- AJOUT : état pour gérer le chargement ---
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state as { from?: string })?.from || '/';

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/login`,
        { email, password }
      );

      localStorage.setItem('token', response.data.user.token);

      toast.success('Connexion réussie !');
      navigate(from);
    } catch (error: any) {
      console.error('Error logging in:', error);

      const errorMessage =
        error.response?.data?.error ||
        error.response?.data?.message ||
        'Erreur inconnue lors de la connexion';

      if (errorMessage.includes('Invalid credentials')) {
        toast.error("Adresse e-mail ou mot de passe incorrect. Veuillez réessayer.");
      } else {
        toast.error(`Erreur lors de la connexion : ${errorMessage}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // ---- NOUVEAU : gestion du clic sur le bouton Google grisé ---
  const handleGoogleClick = () => {
    toast.info("Connexion via Google à venir ! Restez à l'écoute.");
  };

  return (
    <div className="login-container">
      <div className="login-image"></div>

      <div className="login-form-container">
        <form onSubmit={handleLogin}>
          {/* 
            <SignInWithGoogle /> 
            On le commente, ou on le supprime pour désactiver la fonctionnalité
          */}

          {/* Bouton Google grisé / non-fonctionnel */}
          <Button
            // disabled // si on le disable vraiment, on ne peut plus cliquer pour montrer le toast
            onClick={handleGoogleClick}
            appearance="minimal"
            // Juste un style rudimentaire pour montrer qu'il est "désactivé"
            style={{
              backgroundColor: '#ccc',
              cursor: 'not-allowed',
              color: '#666',
              marginBottom: '1rem',
            }}
          >
            Se connecter avec Google (à venir)
          </Button>

          <Divider>OU</Divider>

          <label htmlFor="email">Email *</label>
          <input
            type="email"
            id="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <label htmlFor="password">Mot de passe *</label>
          <input
            type="password"
            id="password"
            placeholder="Mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <a href="/password-forgotten">Mot de passe oublié ?</a>

          <Button
            type="submit"
            className="login-button"
            disabled={isLoading}
          >
            {isLoading ? 'Connexion...' : 'Se connecter'}
          </Button>

          <Divider>OU</Divider>

          <h3>Nouveau sur Rintintin ?</h3>
          <Button
            appearance="minimal"
            className="create-account"
            onClick={() => navigate('/signup')}
          >
            Créer mon compte
          </Button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
