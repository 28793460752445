import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from '@mui/material';
import './CGVModal.css';

interface CGVModalProps {
  isOpen: boolean;
  onClose: () => void;
  cgvContent: string;
}

const CGVModal: React.FC<CGVModalProps> = ({ isOpen, onClose, cgvContent }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      className="cgv-modal"
    >
      <DialogTitle>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          Conditions Générales de Vente
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
            {cgvContent}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CGVModal;
