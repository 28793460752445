import React from 'react';
import './SocialProof.css';

interface SocialProofProps {
  profileImages: string[];
  rating?: number;
  callToAction: string;
}

const SocialProof: React.FC<SocialProofProps> = ({
  profileImages,
  rating = 5,
  callToAction
}) => {
  return (
		<div className="social-proof-container">
			{/* Profile Images */}
			<div className="profile-images">
				{profileImages.map((image, index) => (
              <div
                key={index}
                className="profile-image"
                style={{ backgroundImage: `url(${image})` }}
              />
            ))}
          </div>

          {/* Star Rating */}
          <div className="rating">
            {[...Array(rating)].map((_, index) => (
              <span key={index} className="star">⭐</span>
            ))}
          </div>

			{/* Call to Action Text */}
			<h2>{callToAction}</h2>
		</div>
  );
};

export default SocialProof;
