// src/components/ReservationList/ReservationList.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ReservationList.css';
import { format, parseISO, parse } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  CalendarToday,
  Place,
  Pets,
  Cancel,
  CheckCircle,
  HourglassEmpty,
  Replay,
  RemoveCircle,
  MonetizationOn,
} from '@mui/icons-material';

interface Dog {
  dogId: number;
  name: string;
  breed: string;
}

interface Service {
  serviceId: number;
  serviceName: string;
  location: string;
  question: string;
  allow_online_payment?: boolean;
}

interface Trainer {
  trainerId: number;
  name: string;
  slug: string;
  email: string;
}

interface Booking {
  bookingId: number;
  status: string;
  paymentStatus: string; // Nouveau champ pour refléter le statut du paiement (ex: "paid_online", "unpaid", "paid_on_site")
  dateCreated: string;
  clientAnswer: string;
  startTime: string;
  endTime: string;
  date: string;
  dog: Dog;
  service: Service;
  trainer: Trainer;
  userEmail: string;
}

interface ReservationListProps {
  userId: number;
}

const ReservationList: React.FC<ReservationListProps> = ({ userId }) => {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/get-bookings-user/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        setBookings(response.data.bookings);
      } catch (error) {
        console.error('Erreur lors de la récupération des réservations :', error);
        toast.error('Erreur lors de la récupération des réservations.');
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, [userId]);

  const isCancellable = (status: string) => {
    // Ajout de "confirmed" si besoin
    const cancellableStatuses = ['validated', 'confirmed', 'pending', 'rescheduled'];
    return cancellableStatuses.includes(status);
  };

  const handleConfirmBooking = async (bookingId: number) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/update-booking-status`,
        { booking_id: bookingId, status: 'validated' }, // Ou "confirmed" selon la logique
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response.status === 200) {
        // Mettre à jour le state local
        setBookings((prevBookings) =>
          prevBookings.map((booking) =>
            booking.bookingId === bookingId ? { ...booking, status: 'validated' } : booking
          )
        );
        toast.success('Réservation validée !');
      } else {
        toast.error("Erreur lors de la confirmation de la réservation.");
      }
    } catch (error) {
      console.error('Erreur lors de la confirmation de la réservation :', error);
      toast.error('Erreur lors de la confirmation.');
    }
  };


  const handleCancelBooking = async (bookingId: number) => {
    if (!window.confirm("Êtes-vous sûr de vouloir annuler cette réservation ?")) {
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/cancel-booking`,
        {
          booking_id: bookingId,
          cancelled_by: 'client',
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.status === 200) {
        const { client_email_sent, trainer_email_sent } = response.data;

        setBookings((prevBookings) =>
          prevBookings.map((booking) =>
            booking.bookingId === bookingId
              ? { ...booking, status: 'cancelled_by_client' }
              : booking
          )
        );

        if (client_email_sent) {
          toast.success('Un e-mail de confirmation vous a été envoyé.');
        } else {
          toast.error("Erreur lors de l'envoi de l'e-mail de confirmation au client.");
        }

        if (trainer_email_sent) {
          toast.success('Un e-mail a été envoyé au professionnel.');
        } else {
          toast.error("Erreur lors de l'envoi de l'e-mail au professionnel.");
        }

        if (client_email_sent && trainer_email_sent) {
          toast.success('Réservation annulée et e-mails envoyés avec succès.');
        } else if (client_email_sent || trainer_email_sent) {
          toast.warn(
            "Réservation annulée, mais certains e-mails n'ont pas pu être envoyés."
          );
        }
      } else {
        toast.error("Erreur lors de l'annulation de la réservation.");
      }
    } catch (error: any) {
      console.error("Erreur lors de l'annulation :", error);
      const errorMessage = error.response?.data?.error || "Erreur lors de l'annulation.";
      toast.error(errorMessage);
    }
  };

  const formatDate = (dateString: string) => {
    const date = parseISO(dateString);
    return format(date, 'EEEE d MMMM yyyy', { locale: fr });
  };

  const formatTime = (timeString: string) => {
    const time = parse(timeString, 'HH:mm:ss', new Date());
    return format(time, 'HH:mm');
  };

  const getStatusLabel = (status: string) => {
    switch (status) {
      case 'validated':
        return 'Validée';
      case 'confirmed':
        return 'Confirmée';
      case 'rescheduled':
        return 'Reprogrammée';
      case 'pending':
        return 'En attente de validation';
      case 'pending_reschedule':
        return 'En attente de reprogrammation';
      case 'declined':
        return 'Refusée';
      case 'cancelled_by_pro':
        return 'Annulée par le professionnel';
      case 'cancelled_by_client':
        return 'Annulée par vous';
      case 'pending_client':
        return 'En attente du client';
      default:
        return status;
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'validated':
      case 'confirmed':
        return <CheckCircle style={{ color: '#5cb85c', marginRight: '5px' }} />;
      case 'rescheduled':
        return <Replay style={{ color: '#0275d8', marginRight: '5px' }} />;
      case 'pending':
      case 'pending_reschedule':
        return <HourglassEmpty style={{ color: '#f0ad4e', marginRight: '5px' }} />;
      case 'declined':
        return <RemoveCircle style={{ color: '#d9534f', marginRight: '5px' }} />;
      case 'cancelled_by_pro':
      case 'cancelled_by_client':
        return <Cancel style={{ color: '#999', marginRight: '5px' }} />;
      default:
        return <RemoveCircle style={{ color: '#999', marginRight: '5px' }} />;
    }
  };

  if (loading) {
    return <p className="loading-message">Chargement des réservations...</p>;
  }

  if (bookings.length === 0) {
    return <p className="no-bookings-message">Vous n'avez pas de réservations pour le moment.</p>;
  }

  const today = new Date();

  const pendingBookings: Booking[] = [];
  const upcomingBookings: Booking[] = [];
  const pastBookings: Booking[] = [];
  const declinedBookings: Booking[] = [];

  bookings.forEach((booking) => {
    const bookingDate = parseISO(booking.date);
    if (booking.status === 'pending' || booking.status === 'pending_reschedule' ||
      booking.status === 'pending_client') {
      pendingBookings.push(booking);
    } else if (
      ['declined', 'cancelled_by_pro', 'cancelled_by_client'].includes(booking.status)
    ) {
      declinedBookings.push(booking);
    } else {
      if (bookingDate >= today) {
        upcomingBookings.push(booking);
      } else {
        pastBookings.push(booking);
      }
    }
  });

  // Tri des réservations
  upcomingBookings.sort((a, b) => parseISO(a.date).getTime() - parseISO(b.date).getTime());
  pendingBookings.sort((a, b) => parseISO(a.date).getTime() - parseISO(b.date).getTime());
  pastBookings.sort((a, b) => parseISO(b.date).getTime() - parseISO(a.date).getTime());
  declinedBookings.sort((a, b) => parseISO(b.date).getTime() - parseISO(a.date).getTime());

  // Nouvelle fonction pour afficher l'info paiement en fonction de paymentStatus
  const renderPaymentInfo = (booking: Booking) => {
    const { paymentStatus, service } = booking;
    // Quelques exemples de mapping :
    // "paid_online" => Payée en ligne (Stripe)
    // "paid_on_site" => Payée sur place
    // "unpaid" => Non payée encore
    // Si allow_online_payment = true et paymentStatus = "unpaid", alors client n'a pas encore payé en ligne
    // Si allow_online_payment = false, c'est forcément paiement sur place
    let text = '';
    let iconColor = '#f0ad4e';

    if (paymentStatus === 'paid_online') {
      text = 'Payée en ligne (Stripe)';
      iconColor = '#5cb85c';
     } else if (paymentStatus === 'paid_forfait') {
        text = 'Payé via forfait';
        iconColor = '#5cb85c';
    } else if (paymentStatus === 'paid_on_site') {
      text = 'Payée sur place';
      iconColor = '#5cb85c';
    } else {
      // paymentStatus = 'unpaid' ou autre
      if (service.allow_online_payment) {
        text = 'Pas encore payée (prévu en ligne)';
      } else {
        text = 'Paiement sur place à prévoir';
      }
    }

    return (
      <p>
        <MonetizationOn style={{ verticalAlign: 'middle', marginRight: '5px', color: iconColor }} />
        <strong>Paiement :</strong> {text}
      </p>
    );
  };

  const renderBookingCard = (booking: Booking, isPast = false) => {
    const statusIcon = getStatusIcon(booking.status);

    return (
      <div
        key={booking.bookingId}
        className={`reservation-card ${booking.status} ${isPast ? 'past-disabled' : ''}`}
      >
        <div className="reservation-card-header">
          <h3>
            {statusIcon}
            {booking.service.serviceName}
          </h3>
          <span className={`status-badge ${booking.status}`}>
            {getStatusLabel(booking.status)}
          </span>
        </div>
        <div className="reservation-card-body">
          <p>
            <CalendarToday style={{ verticalAlign: 'middle', marginRight: '5px' }} />
            <strong>Date :</strong> {formatDate(booking.date)} de {formatTime(booking.startTime)} à {formatTime(booking.endTime)}
          </p>
          <p>
            <Place style={{ verticalAlign: 'middle', marginRight: '5px' }} />
            <strong>Lieu :</strong> {booking.service.location}
          </p>
          <p>
            <strong>Pro :</strong>{' '}
            <Link to={`/pro/${booking.trainer.slug}`} className="trainer-link">
              {booking.trainer.name}
            </Link>
          </p>
          <p>
            <Pets style={{ verticalAlign: 'middle', marginRight: '5px' }} />
            <strong>Chien :</strong> {booking.dog.name} ({booking.dog.breed})
          </p>
          {renderPaymentInfo(booking)}
          <div className="reservation-actions">
          {booking.status === 'pending_client' && !isPast && (
              <button
                onClick={() => handleConfirmBooking(booking.bookingId)}
                className="confirm-button"
              >
                Confirmer
              </button>
            )}
            {isCancellable(booking.status) && !isPast ? (
              <button onClick={() => handleCancelBooking(booking.bookingId)}>
                Annuler
              </button>
            ) : (
              <span className="no-action">Aucune action disponible</span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="reservation-list-container">
      <div className="reservation-legend">
        <h2>Vos réservations</h2>
        <p>Retrouvez ci-dessous toutes vos réservations classées par statut et par date.</p>
        <div className="legend-items">
          <div><CheckCircle style={{ color: '#5cb85c' }} /> Validée / Confirmée</div>
          <div><Replay style={{ color: '#0275d8' }} /> Reprogrammée</div>
          <div><HourglassEmpty style={{ color: '#f0ad4e' }} /> En attente</div>
          <div><RemoveCircle style={{ color: '#d9534f' }} /> Refusée</div>
          <div><Cancel style={{ color: '#999' }} /> Annulée (Pro ou Client)</div>
        </div>
      </div>

      <div className="reservation-list">
        {upcomingBookings.length > 0 && (
          <div className="booking-section upcoming">
            <h3>À venir</h3>
            <p className="section-description">Les réservations confirmées, validées ou reprogrammées qui n'ont pas encore eu lieu.</p>
            {upcomingBookings.map((booking) => renderBookingCard(booking, false))}
          </div>
        )}

        {pendingBookings.length > 0 && (
          <div className="booking-section pending">
            <h3>En attente</h3>
            <p className="section-description">Les réservations qui sont en cours de validation ou de reprogrammation.</p>
            {pendingBookings.map((booking) => renderBookingCard(booking, false))}
          </div>
        )}

        {pastBookings.length > 0 && (
          <div className="booking-section past">
            <h3>Passées</h3>
            <p className="section-description">Les réservations déjà effectuées dans le passé (non interactives).</p>
            {pastBookings.map((booking) => renderBookingCard(booking, true))}
          </div>
        )}

        {declinedBookings.length > 0 && (
          <div className="booking-section declined">
            <h3>Annulées ou refusées</h3>
            <p className="section-description">Les réservations qui ont été refusées ou annulées (par vous ou le professionnel).</p>
            {declinedBookings.map((booking) => renderBookingCard(booking, true))}
          </div>
        )}

        {upcomingBookings.length === 0 &&
          pendingBookings.length === 0 &&
          pastBookings.length === 0 &&
          declinedBookings.length === 0 && (
            <p className="no-bookings-message">
              Vous n'avez pas de réservations pour le moment.
            </p>
          )}
      </div>
    </div>
  );
};

export default ReservationList;
