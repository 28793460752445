/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { MapContainer, TileLayer, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import SearchBar from '../../components/SearchBar/SearchBar';
import TrainerCard from '../../components/TrainerCard/TrainerCard';
import MarkerWithIcon from '../../components/MarkerWithIcon/MarkerWithIcon';
import { ClipLoader } from 'react-spinners';
import './TrainerList.css';

interface Trainer {
  id: number;
  slug: string;
  name: string;
  description: string;
  profile_photo_url: string;
  ville: string;
  rating: number;
  education_methods: string[];
  specialties: string[];
  is_professional: boolean;
  lat?: number;
  lon?: number;
  distance_km?: number;
  services?: Array<{
    service_name: string;
    next_availability?: string;
  }>;
}

const MOBILE_ITEMS_PER_PAGE = 4;
const DESKTOP_ITEMS_PER_PAGE = 12;

const MapView: React.FC<{
  searchCoordinates: [number, number];
  trainers: Trainer[];
  hoveredTrainerId: number | null;
}> = ({ searchCoordinates, trainers, hoveredTrainerId }) => {
  const map = useMap();

  useEffect(() => {
    map.setView(searchCoordinates, 12);
  }, [map, searchCoordinates]);

  useEffect(() => {
    const hovered = trainers.find((t) => t.id === hoveredTrainerId);
    if (hovered?.lat && hovered?.lon) {
      map.flyTo([hovered.lat, hovered.lon], 14, { duration: 0.5 });
    }
  }, [hoveredTrainerId, trainers, map]);

  return (
    <>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {trainers.map((trainer) => (
        trainer.lat && trainer.lon && (
          <MarkerWithIcon
            key={trainer.id}
            position={[trainer.lat, trainer.lon]}
            eventHandlers={{
              mouseover: (e: any) => e.target.openPopup(),
              mouseout: (e: any) => e.target.closePopup(),
							click: (e: any) => {
								window.location.href = `/pro/${trainer.slug}`;
							}
            }}
          >
            <Popup>
              <strong>{trainer.name}</strong>
              <br />
              {trainer.ville}
              {trainer.distance_km && (
                <><br />Distance: {trainer.distance_km.toFixed(1)} km</>
              )}
            </Popup>
          </MarkerWithIcon>
        )
      ))}
    </>
  );
};

const TrainerList: React.FC = () => {
  const [searchParams] = useSearchParams();
  const address = searchParams.get('address') || '';
  const profession = searchParams.get('profession') || '';
  const latParam = searchParams.get('lat');
  const lonParam = searchParams.get('lon');

  const [trainers, setTrainers] = useState<Trainer[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [hoveredTrainerId, setHoveredTrainerId] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const searchCoordinates: [number, number] | null = latParam && lonParam
    ? [parseFloat(latParam), parseFloat(lonParam)]
    : null;
    
  const ITEMS_PER_PAGE = windowWidth >= 768 ? DESKTOP_ITEMS_PER_PAGE : MOBILE_ITEMS_PER_PAGE;

  const fetchTrainers = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      // 1. First fetch trainers
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-trainers`,
        { params: { address, profession } }
      );

      // 2. For each trainer, fetch their services and availabilities
      const trainersWithServices = await Promise.all(
        response.data.trainers.map(async (trainer: Trainer) => {
          try {
            // Fetch services for this trainer
            const servicesResponse = await axios.get(
              `${process.env.REACT_APP_API_BASE_URL}/get-services/${trainer.id}`
            );

            // Get visible services only
            const visibleServices = servicesResponse.data.services.filter(
              (s: any) => s.visible
            );

            // For each service, get next availability
            const servicesWithAvailability = await Promise.all(
              visibleServices.slice(0, 2).map(async (service: any) => {
                try {
                  const availabilityRes = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/get-available-dates/${service.service_id}`
                  );

                  const nextAvailability = availabilityRes.data.availableDates[0];
                  return {
                    service_name: service.service_name,
                    next_availability: nextAvailability || undefined
                  };
                } catch {
                  return {
                    service_name: service.service_name,
                    next_availability: undefined
                  };
                }
              })
            );

            return {
              ...trainer,
              services: servicesWithAvailability
            };
          } catch (error) {
            console.error(`Error fetching services for trainer ${trainer.id}:`, error);
            return {
              ...trainer,
              services: []
            };
          }
        })
      );

      setTrainers(trainersWithServices);
    } catch (err) {
      setError("Une erreur est survenue lors du chargement des professionnels.");
    } finally {
      setLoading(false);
    }
  }, [address, profession]);

  useEffect(() => {
    fetchTrainers();
  }, [fetchTrainers]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (loading) {
    return (
      <div className="trainer-list">
				<div className="search-section">
					<SearchBar />
				</div>
        <div className="loading-state">
          <ClipLoader size={50} color="#e0b127" loading />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="trainer-list">
				<div className="search-section">
					<SearchBar />
				</div>
        <div className="error-state">{error}</div>
      </div>
    );
  }

  const pageCount = Math.ceil(trainers.length / ITEMS_PER_PAGE);
  const currentTrainers = trainers.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <div className="trainer-list">
      <div className="search-section">
        <SearchBar />
      </div>

      {searchCoordinates && (
        <div className="map-section" style={{ zIndex: 1 }}>
          <MapContainer>
            <MapView
              searchCoordinates={searchCoordinates}
              trainers={trainers}
              hoveredTrainerId={hoveredTrainerId}
            />
          </MapContainer>
        </div>
      )}

      <div className="results-section">
        <div className="trainers-container">
          {currentTrainers.map((trainer) => (
            <TrainerCard
              key={trainer.id}
              slug={trainer.slug}
              profilePhotoUrl={trainer.profile_photo_url}
              name={trainer.name}
              ville={trainer.ville}
              description={trainer.description}
              isProfessional={trainer.is_professional}
              ratingContent={trainer.rating}
              onHover={() => setHoveredTrainerId(trainer.id)}
              onLeave={() => setHoveredTrainerId(null)}
              services={trainer.services}
            />
          ))}
        </div>

        {pageCount > 1 && (
          <div className="pagination">
            {Array.from({ length: pageCount }, (_, i) => (
              <button
                key={i + 1}
                className={`pagination-button ${currentPage === i + 1 ? 'active' : ''}`}
                onClick={() => {
                  setCurrentPage(i + 1);
                  window.scrollTo(0, 0);
                }}
              >
                {i + 1}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TrainerList;
