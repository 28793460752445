/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import SearchBar from '../../components/SearchBar/SearchBar';
import './HomePage.css';
import BulletPointDescription from '../../components/BulletPointDescription/BulletPointDescription';
import FAQ from '../../components/FAQ/FAQ';
import Hero from './components/Hero/Hero';
import { useAuth } from '../../context/AuthContext';

const imageUrls = [
  'https://rintintin-bucket.s3.eu-west-3.amazonaws.com/00001pictureRintintin.jpg',
];

const HomePage: React.FC = () => {
  const [backgroundImage, setBackgroundImage] = useState<string>('');
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      const redirectUrl = localStorage.getItem('redirectUrl');
      if (redirectUrl) {
        localStorage.removeItem('redirectUrl');
        window.location.href = redirectUrl;
      }
    }
  }, [isAuthenticated]);


  useEffect(() => {
    const getRandomImageUrl = () => {
      const randomIndex = Math.floor(Math.random() * imageUrls.length);
      return imageUrls[randomIndex];
    };
    setBackgroundImage(getRandomImageUrl());
  }, []);

  return (
    <div className="home-page">
      <Hero />

			<BulletPointDescription />
      <FAQ />
    </div>
  );
};

export default HomePage;
