/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { 
  Box, 
  Typography,
  Card, 
  CardContent, 
  CircularProgress, 
  Avatar, 
  Grid, 
  Divider, 
  IconButton 
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import LanguageIcon from '@mui/icons-material/Language';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

const iconMap: { [key: string]: JSX.Element } = {
  Facebook: <FacebookIcon fontSize="small"/>,
  Instagram: <LanguageIcon fontSize="small"/>,
  LinkedIn: <LinkedInIcon fontSize="small"/>,
  Twitter: <TwitterIcon fontSize="small"/>,
};

interface Contact {
  network_name: string;
  network_link: string;
}

interface Trainer {
  id: number;
  name: string;
  ville?: string;
  email: string;
  profile_photo_url?: string;
  contacts?: Contact[];
}

interface ServiceInfo {
  service_id: number;
  serviceName: string;       // si le backend renvoie "serviceName"
  price: number;
  duration: number;
  location: string;
  description: string;
  allow_online_payment: boolean;
  // etc. (pause_before, pause_after, max_clients...) si besoin
}

/** Interface du booking complet renvoyé par get_booking_details_full */
interface BookingData {
  booking_id: number;
  date: string;            // "2025-01-31"
  startTime: string;       // "10:00"
  endTime?: string | null; // s'il y en a un
  status: string;
  paid_by_forfait?: number | null; // <-- important

  trainer: Trainer;
  service: ServiceInfo;

  user?: {        // si besoin d'infos user
    user_id: number;
    name: string;
    lastName: string;
    email: string;
  };

  // etc. dog, etc. si vous voulez
}

const BookingConfirmation: React.FC = () => {
  const [searchParams] = useSearchParams();
  const bookingId = searchParams.get('booking_id');

  // On stocke tout le booking ici
  const [bookingData, setBookingData] = useState<BookingData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const finalize = async () => {
      if (!bookingId) {
        setError("Données manquantes (booking_id).");
        setLoading(false);
        return;
      }
  
      const token = localStorage.getItem('token');
      if (!token) {
        setError("Vous n'êtes pas authentifié. Veuillez vous reconnecter.");
        setLoading(false);
        return;
      }
  
      try {
        // 1) Appel API
        const detailsRes = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/get-booking-details/${bookingId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        // 2) On extrait l’objet "booking"
        const { booking } = detailsRes.data; 
        if (!booking) {
          setError("Réservation non trouvée");
          setLoading(false);
          return;
        }

        // 3) On stocke le booking complet
        setBookingData(booking);

      } catch (e: any) {
        console.error("Erreur lors de la récupération :", e);
        setError("Une erreur est survenue lors de la récupération des infos.");
      } finally {
        setLoading(false);
      }
    };
  
    finalize();
  }, [bookingId]);

  if (loading) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="80vh"
        bgcolor="#f9f9f9"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="80vh"
        bgcolor="#f9f9f9"
        p={2}
      >
        <Typography variant="h6" color="error">{error}</Typography>
      </Box>
    );
  }

  // A ce stade, plus d'erreur, plus de loading => on vérifie bookingData
  if (!bookingData) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="80vh"
        bgcolor="#f9f9f9"
        p={2}
      >
        <Typography variant="h6">Aucune donnée de réservation disponible</Typography>
      </Box>
    );
  }

  // On extrait depuis bookingData
  const {
    trainer,
    service,
    date,
    startTime,
    paid_by_forfait
  } = bookingData;

  // Formatage
  const formattedDate = dayjs(date).locale('fr').format('dddd D MMMM YYYY');
  const formattedTime = dayjs(startTime, ['HH:mm:ss', 'HH:mm']).format('HH:mm');

  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      minHeight="100vh"
      bgcolor="#f9f9f9"
      p={2}
    >
      <Card 
        sx={{ 
          maxWidth: 500, 
          width: '100%', 
          borderRadius: '12px', 
          boxShadow: '0 8px 20px rgba(0,0,0,0.1)' 
        }}
      >
        <CardContent>
          <Box textAlign="center" mb={2}>
            <CheckCircleOutlineIcon sx={{ fontSize: 50, color: '#4caf50' }} />
            <Typography variant="h5" sx={{ mt: 1, fontWeight: 'bold' }}>
              Réservation enregistrée !
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
              Votre réservation a été prise en compte.
            </Typography>
          </Box>

          <Divider sx={{ my: 3 }} />

          {/* Informations sur la réservation */}
          <Box mb={3}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
              Détails de la réservation
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>Service :</strong> {service.serviceName} 
              ({service.price}€ / {service.duration}min)
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>Date :</strong> {formattedDate}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>Heure :</strong> {formattedTime}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>Lieu :</strong> {service.location}
            </Typography>

            {/* --- Affichage du mode de paiement --- */}
            {paid_by_forfait ? (
              <Typography variant="body2" color="info.main" sx={{ mt: 1 }}>
                ✅ Réservation payée via forfait (ID {paid_by_forfait})
              </Typography>
            ) : (
              service.allow_online_payment ? (
                <Typography variant="body2" color="success.main" sx={{ mt: 1 }}>
                  Paiement en ligne (déjà réglé ou à régler)
                </Typography>
              ) : (
                <Typography variant="body2" color="warning.main" sx={{ mt: 1 }}>
                  Paiement sur place
                </Typography>
              )
            )}
          </Box>

          <Divider sx={{ mb: 3 }} />

          {/* Informations sur l'éducateur */}
          <Box mb={3}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Votre éducateur
            </Typography>
            <Grid container spacing={2}>
              <Grid item>
                <Avatar 
                  src={trainer.profile_photo_url || ''} 
                  alt={trainer.name} 
                  sx={{ width: 56, height: 56, bgcolor: '#f6c674', color: '#333' }}
                >
                  {trainer.name.charAt(0)}
                </Avatar>
              </Grid>
              <Grid item xs>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {trainer.name}
                </Typography>
                {trainer.ville && (
                  <Typography variant="body2" color="text.secondary">
                    📍 {trainer.ville}
                  </Typography>
                )}
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  {trainer.email}
                </Typography>
              </Grid>
            </Grid>

            {trainer.contacts && trainer.contacts.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Retrouvez l'éducateur sur :
                </Typography>
                <Box display="flex" gap={1}>
                  {trainer.contacts.map((contact, idx) => {
                    const IconComponent = iconMap[contact.network_name] || <LanguageIcon fontSize="small"/>;
                    return (
                      <IconButton 
                        key={idx} 
                        component="a" 
                        href={contact.network_link} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        sx={{ 
                          backgroundColor: '#fff', 
                          border: '1px solid #ddd', 
                          '&:hover': { backgroundColor: '#f6c674', borderColor: '#f6c674', color: '#333' } 
                        }}
                      >
                        {IconComponent}
                      </IconButton>
                    );
                  })}
                </Box>
              </Box>
            )}
          </Box>

          <Divider sx={{ mb: 3 }} />

          <Box textAlign="center">
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Un e-mail de confirmation vous a été envoyé.
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Merci d'avoir fait confiance à Rintintin !
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </Box>
  );
};

export default BookingConfirmation;
