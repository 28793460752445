// src/components/FAQ/FAQ.tsx

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './FAQ.css';
import { Link } from 'react-router-dom';
import dogImage from '../../assets/dog-image.jpg';

interface FAQItem {
  question: string;
  answer: string;
}

const faqItems: FAQItem[] = [
  { question: 'Comment puis-je être référencé sur Rintintin ?', answer: "Vous pouvez créer votre profil directement depuis rintintin-pro.com/login ou en cliquant sur 'Je suis un professionnel' !" },
  { question: 'Quels types de services sont proposés par les professionnels ?', answer: "Nos pro offrent une variété de services, notamment l'apprentissage de la marche en laisse, le rappel, la socialisation, et bien plus. Chaque service est décrit en détail dans leur profil." },
  { question: 'Je suis un professionnel canin, comment puis-je être sur Rintintin ?', answer: "Si vous n'êtes pas sur Rintintin, contactez-nous directement et nous vous accompagnerons dans la création de votre profil !" },
];

const FAQ: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <motion.div
      className="faq-container"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
    >
      <div className="faq-content-wrapper">
        <motion.div
          className="faq-section"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.3 }}
        >
          <h2>Des réponses à tes questions</h2>
          <div className="faq-items">
            {faqItems.map((item, index) => (
              <motion.div
                key={index}
                className="faq-item"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 + 0.5 }}
              >
                <div
                  className="faq-question"
                  onClick={() => toggleFAQ(index)}
                >
                  {item.question}
                  <motion.span
                    animate={{ rotate: activeIndex === index ? 180 : 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    ▼
                  </motion.span>
                </div>
                <AnimatePresence>
                  {activeIndex === index && (
                    <motion.div
                      className="faq-answer"
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      {item.answer}
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ))}
          </div>
        </motion.div>

        <motion.div
          className="faq-image-section"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.3 }}
        >
          <motion.img
            src={dogImage}
            alt="FAQ"
            className="faq-image"
            transition={{ duration: 0.3 }}
          />
          <div className="faq-cta-container">
            <p className="faq-cta-text">POSE-NOUS TES QUESTIONS</p>
            <Link to="/contact">
              <motion.button
                className="faq-cta-button"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                ICI
              </motion.button>
            </Link>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default FAQ;
