// MarkerWithIcon.tsx
import React from 'react';
import { Marker as LeafletMarker, MarkerProps } from 'react-leaflet';
import L from 'leaflet';

// On importe les icônes par défaut de Leaflet
import markerIcon from '../../assets/paw-marker.png';

// Props étendues pour forcer "icon?: any;"
interface MarkerWithIconProps extends MarkerProps {
  icon?: any;
}

// On définit un icône Leaflet "classique"
// Vous pouvez adapter iconSize, iconAnchor, etc. selon vos goûts
const defaultIcon = L.icon({
  iconUrl: markerIcon,
  iconSize: [80, 80],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const MarkerWithIcon: React.FC<MarkerWithIconProps> = (props) => {
  return <LeafletMarker icon={defaultIcon} {...props} />;
};

export default MarkerWithIcon;
