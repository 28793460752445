import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../../context/AuthContext';

const GoogleAuthCallback: React.FC = () => {
  const [isProcessing, setIsProcessing] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();
  const hasProcessedRef = useRef(false);

  useEffect(() => {
    const processGoogleAuth = async () => {
      // Prevent multiple requests
      if (hasProcessedRef.current) return;
      hasProcessedRef.current = true;

      // Get the code parameter from the URL
      const queryParams = new URLSearchParams(location.search);
      const code = queryParams.get('code');
			const state = queryParams.get('state');

      if (!code) {
        toast.error('Code d\'autorisation Google manquant');
        navigate('/login');
        return;
      }

      try {
        // Exchange the code for tokens and user info
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/auth/google/callback?code=${code}&state=${state}`
        );

        const { token, user_id, email, name } = response.data;

        // Store token
        localStorage.setItem('token', token);

        // Create a complete user object
        const userData = {
          user_id: typeof user_id === 'string' ? parseInt(user_id, 10) : user_id,
          name,
          email,
        };

        // If we need more user data, fetch the complete profile
        const profileResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/get-profile`,
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        // Merge profile data with our user data
        const completeUserData = {
          ...userData,
          ...profileResponse.data,
        };

        // Update authentication context with complete user data
        login(token, completeUserData);

        toast.success('Connexion avec Google réussie !');

        // Redirect to the saved redirect path or default to profile creation
        const redirectPath = localStorage.getItem('redirectAfterAuth') || '/';
				console.log(redirectPath);
        localStorage.removeItem('redirectAfterAuth'); // Clean up

        navigate(redirectPath);
      } catch (error) {
        console.error('Error processing Google authentication:', error);
        toast.error('Erreur lors de l\'authentification Google. Veuillez réessayer.');
        navigate('/login');
      } finally {
        setIsProcessing(false);
      }
    };

    processGoogleAuth();
  }, [location, login, navigate]);

  return (
    <div className="google-auth-callback">
      {isProcessing && (
        <div className="loading-container">
          <div className="spinner"></div>
          <p>Traitement de l'authentification Google...</p>
        </div>
      )}
    </div>
  );
};

export default GoogleAuthCallback;
