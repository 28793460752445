// src/pages/ForfaitSuccessPage/ForfaitSuccessPage.tsx

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Box, CircularProgress, Typography } from '@mui/material';

const ForfaitSuccessPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const session_id = new URLSearchParams(location.search).get('session_id');

  // Empêche d'appeler deux fois la confirmation
  const [hasConfirmed, setHasConfirmed] = useState(false);

  useEffect(() => {
    const confirmPurchase = async () => {
      try {
        // Récupération du forfait stocké dans le localStorage
        const pendingForfait = JSON.parse(localStorage.getItem('pendingForfait') || '{}');
        if (!pendingForfait?.forfait_id) {
          throw new Error('Forfait information missing');
        }
  
        // Appel au backend
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/confirm-forfait-purchase`,
          {
            session_id,                          // On a la query param
            forfait_id: pendingForfait.forfait_id
          },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          }
        );
  
        // Nettoyage
        localStorage.removeItem('pendingForfait');
  
        if (response.data.message === 'Forfait activé avec succès') {
          toast.success('Forfait activé avec succès !');
          navigate(`/mes-forfaits`);
        } else if (response.data.message === 'Forfait déjà activé') {
          toast.info('Forfait déjà activé');
          navigate(`/mes-forfaits`);
        } else {
          toast.error('Erreur lors de la confirmation du forfait');
          navigate('/mes-forfaits');
        }
      } catch (error) {
        console.error('Erreur:', error);
        toast.error('Échec de la confirmation du forfait');
        localStorage.removeItem('pendingForfait');
        navigate('/mes-forfaits');
      }
    };
  
    if (!session_id) {
      navigate('/mes-forfaits');
      return;
    }
  
    if (!hasConfirmed) {
      setHasConfirmed(true);
      confirmPurchase();
    }
  }, [session_id, navigate, hasConfirmed]);
  

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        minHeight: '100vh',
        textAlign: 'center',
        p: 3
      }}
    >
      <CircularProgress size={60} sx={{ mb: 3 }} />
      <Typography variant="h4" sx={{ mb: 2 }}>
        Traitement de votre achat...
      </Typography>
      <Typography variant="body1" sx={{ color: 'text.secondary' }}>
        Veuillez patienter pendant que nous confirmons votre transaction.
      </Typography>
    </Box>
  );
};

export default ForfaitSuccessPage;
