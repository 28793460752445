import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./SearchBar.css";
import { fetchAutocomplete, AutocompleteItem } from "../../services/autocompleteService";
import { LoadScript, Autocomplete } from '@react-google-maps/api';

/** Petite fonction pour slugifier un texte, ex. "Toiletteur canin" -> "toiletteur-canin" */
function slugify(str: string): string {
  return str
    .toLowerCase()
    .normalize("NFD").replace(/[\u0300-\u036f]/g, "") // enlever les accents
    .replace(/\s+/g, "-") // remplacer espaces par tirets
    .replace(/[^a-z0-9-]/g, "") // enlever caractères non autorisés
    .replace(/-+/g, "-")        // éviter tirets multiples
    .replace(/^-|-$/g, "");     // enlever tirets de début/fin
}

interface SearchBarProps {
  onSearch?: () => void;
  withMarginTop?: boolean;
}

const DEFAULT_PROFESSION = "Éducateur canin"; // Valeur par défaut (libellé)
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;
const LIBRARIES: ("places")[] = ["places"];

const SearchBar: React.FC<SearchBarProps> = ({ onSearch, withMarginTop }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // ÉTATS
  const [searchQuoi, setSearchQuoi] = useState("");
  const [searchOu, setSearchOu] = useState("");
  const [suggestions, setSuggestions] = useState<AutocompleteItem[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);


  const [selectedProfessionSlug, setSelectedProfessionSlug] = useState<string | null>(null);

  const suggestionRef = useRef<HTMLDivElement>(null);

  // Add these new states
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

  /** Récupérer "quoi" et "ou" depuis l'URL si déjà présents */
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const quoiParam = params.get("quoi");
    const ouParam = params.get("ou");
    const professionParam = params.get("profession");

    if (quoiParam) {
      setSearchQuoi(quoiParam);
    }
    if (ouParam) {
      setSearchOu(ouParam);
    }
    if (professionParam) {
      setSelectedProfessionSlug(professionParam);
    }
  }, [location.search]);

  /** Autocomplétion : quand l'utilisateur tape dans "Quoi" */
  useEffect(() => {
    if (searchQuoi.trim().length > 0) {
      fetchAutocomplete(searchQuoi).then((res) => setSuggestions(res));
    } else {
      setSuggestions([]);
    }
  }, [searchQuoi]);

  /** Fermer la liste si clic hors zone */
  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (suggestionRef.current && !suggestionRef.current.contains(e.target as Node)) {
        setShowSuggestions(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  /** Quand l'utilisateur clique sur une suggestion */
  const handleSelectSuggestion = (item: AutocompleteItem) => {
    // On réinitialise le message d'erreur
    setErrorMessage(null);

    if (item.type === "pro") {
      // Ex : redirection directe vers un profil
      navigate(`/pro/${item.slug}`);
    } else if (item.type === "profession") {
      // On mémorise le slug de la profession
      setSelectedProfessionSlug(item.slug || null);
      setSearchQuoi(item.name); // On met le libellé dans le champ
    }
    setShowSuggestions(false);
  };

  /** Géocoder la ville */
  const getCoordinates = async (city: string): Promise<[number, number]> => {
    try {
      const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(city)}&format=json&limit=1`;
      const resp = await fetch(url);
      const data = await resp.json();
      if (data && data.length > 0) {
        return [parseFloat(data[0].lat), parseFloat(data[0].lon)];
      }
    } catch (error) {
      console.error("Nominatim error:", error);
    }
    return [0, 0];
  };

  /** Lancer la recherche */
  const handleSearch = async () => {
    setErrorMessage(null);

    // Vérification de la ville
    if (!searchOu.trim()) {
      setErrorMessage("Pour lancer la recherche, veuillez saisir une ville.");
      return;
    }

    // Calcul des coordonnées
    const [cityLat, cityLon] = await getCoordinates(searchOu);
    const lat = cityLat;
    const lon = cityLon;

    // Préparation des paramètres
    const params = new URLSearchParams();

    // Modification de la logique de gestion de la profession
    if (selectedProfessionSlug) {
      params.set("profession", selectedProfessionSlug);
    } else if (searchQuoi.trim() === DEFAULT_PROFESSION) {
      params.set("profession", "trainers");
    } else {
      // On conserve la profession existante si elle existe dans l'URL
      const currentProfession = new URLSearchParams(location.search).get("profession");
      if (currentProfession) {
        params.set("profession", currentProfession);
      } else {
        const typedSlug = slugify(searchQuoi.trim());
        if (!typedSlug) {
          setErrorMessage("Veuillez sélectionner un métier (ex: Éducateur canin).");
          return;
        }
        params.set("profession", typedSlug);
      }
    }

    params.set("address", searchOu.trim());
    if (lat !== 0) params.set("lat", String(lat));
    if (lon !== 0) params.set("lon", String(lon));
    params.set("quoi", searchQuoi.trim());
    params.set("ou", searchOu.trim());

    // On va sur la page "trainers"
    navigate(`/trainers?${params.toString()}`);
  };

  // Add this new handler
  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.formatted_address) {
        setSearchOu(place.formatted_address);
        // If you need coordinates, you can get them like this:
        // const lat = place.geometry?.location?.lat();
        // const lng = place.geometry?.location?.lng();
      }
    }
  };

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={LIBRARIES}>
      <div className={`search-bar-container ${withMarginTop ? "with-margin" : ""}`}>
        <div className="search-bar">
          {/* Champ "Quoi" */}
          <div className="search-input-container suggestions-wrapper" ref={suggestionRef}>
            <label>Que cherches-tu ?</label>
            <input
              className="search-input"
              type="text"
              placeholder={DEFAULT_PROFESSION}
              value={searchQuoi}
              onChange={(e) => {
                setSearchQuoi(e.target.value);
                setShowSuggestions(true);
                setSelectedProfessionSlug(null);
              }}
              onFocus={() => setShowSuggestions(true)}
            />

            {showSuggestions && (
              <div className="suggestions-dropdown">
                {suggestions.filter(item => item.type !== "service").length > 0 ? (
                  <ul>
                    {["profession", "pro"].map(type => {
                      const filteredItems = suggestions.filter(item => item.type === type);
                      return filteredItems.length > 0 ? (
                        <li key={type} className="suggestion-category">
                          <div className="suggestion-header">
                            {type === "profession" ? "Métiers" : "Professionnels"}
                          </div>
                          <ul className="suggestion-items">
                            {filteredItems.map((item) => (
                              <li
                                key={`${item.type}-${item.id}`}
                                onClick={() => handleSelectSuggestion(item)}
                              >
                                {item.name}
                                {item.icon && (
                                  <span className="suggestion-type"><img src={item.icon} alt={item.name} style={{ width: "20px", height: "20px" }} /></span>
                                )}
                              </li>
                            ))}
                          </ul>
                        </li>
                      ) : null;
                    })}
                  </ul>
                ) : (
                  searchQuoi.trim().length > 0 && (
                    <div className="no-results-message">Aucun résultat</div>
                  )
                )}
              </div>
            )}
          </div>

          <div className="separator" />

          {/* Updated Où field */}
          <div className="search-input-container">
            <label>Où ?</label>
						{!showSuggestions && (
            <Autocomplete
              onLoad={onLoad}
              onPlaceChanged={onPlaceChanged}
              restrictions={{ country: "fr" }} // Restrict to France
            >
              <input
                className="search-input"
                type="text"
                placeholder="Tapez une ville..."
                value={searchOu}
                onChange={(e) => setSearchOu(e.target.value)}
              />
            </Autocomplete>
						)}
          </div>

          <div className="separator" />

          {/* Bouton Rechercher */}
          <button className="search-btn" onClick={handleSearch}>
            Rechercher
          </button>
        </div>

        {/* Affichage d'un éventuel message d'erreur */}
        {errorMessage && (
          <div className="search-error-message">
            {errorMessage}
          </div>
        )}
      </div>
    </LoadScript>
  );
};

export default SearchBar;

