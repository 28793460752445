import './Hero.css';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import SocialProof from './SocialProof';
import { motion } from 'framer-motion';
export default function Hero() {
	return (
    <div
      className="hero-container"
      style={{
        backgroundImage: `url(https://rintintin-bucket.s3.eu-west-3.amazonaws.com/00001pictureRintintin.jpg)`,
      }}
    >
      <div className="hero-overlay"></div>
      <div className="hero-content">
        <h2>
          <span className="styled-hero-text">Simplifiez</span> votre quotidien{" "}
          <span className="styled-hero-text">chouchoutez</span> le leur
        </h2>
        <div className="hero-search-container">
          <SearchBar onSearch={() => {}} />
        </div>
      </div>
			<motion.div
				initial={{ opacity: 0, y: 100 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.5, delay: 0.8 }}
				className="social-proof-main-container"
			>
				<SocialProof
          profileImages={[
            'https://www.dogstrust.org.uk/images/800x600/assets/2025-03/toffee%202.jpg',
						'https://cdn-prod.medicalnewstoday.com/content/images/articles/322/322868/golden-retriever-puppy.jpg',
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6NY09lpUBneuPC7s-x88RHEFrF_tFaL1Stw&s',
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPyapNffxhWhM3a4bOCy1guc2QdLN5nDcH8g&s',
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-7TOInp_nBpD0xu-XTrXPoQeG9GFC11Z7QA&s'
          ]}
          callToAction="Rejoins-les !"
        />
			</motion.div>
    </div>
  );
}
