import React, { useEffect, useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import texts from '../../texts.json';
import logo from '../../assets/logo.png';
import { motion, AnimatePresence } from 'framer-motion';

const Navbar: React.FC = () => {
  const { user, isAuthenticated, logout } = useAuth();
  const [isFloating, setIsFloating] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsFloating(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsExpanded((prev) => !prev);
  };

  const menuVariants = {
    closed: {
      height: 0,
      opacity: 0,
      transition: {
        duration: 0.3,
        when: "afterChildren"
      }
    },
    open: {
      height: "auto",
      opacity: 1,
      transition: {
        duration: 0.3,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const menuItemVariants = {
    closed: {
      y: -20,
      opacity: 0
    },
    open: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <nav className={`navbar ${isFloating ? 'floating' : 'normal'}`}>
      <div className="logo">
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
          <motion.img
            src={logo}
            alt="Rintintin Logo"
            className="logo-image"
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          />
        </Link>
      </div>

      <motion.div
        className="menu-icon"
        onClick={toggleMenu}
        animate={{ rotate: isExpanded ? 90 : 0 }}
        transition={{ duration: 0.3 }}
      >
        ☰
      </motion.div>

      {/* Desktop Navigation */}
      <div className="nav-links">
        <Link to="/about" className="nav-link">À propos</Link>
        <a
          href="https://d14e5fa6.sibforms.com/serve/MUIFAD207ccX5oMui0at721djKI_keyFtoeBXBcBdV7lKP1HJoAE258rc0PbUxAGrz2fzLjgtRdyT_H4Y1HvPNGdvVJ0esP_CMozTgN1QW6vmYpsHE0pPQt5J1Xc6D9GiKDIx7Jjjh7oN_M5SK016tUKkCGlbr1RRtSqO6sYj7nHXhMqKyDqKWiFlFUkHTLbya2VsJ6OHCbH7gR0"
          target="_blank"
          rel="noopener noreferrer"
          className="nav-link"
        >
          Newsletter
        </a>
      </div>

      <div className="buttons">
        <a href="https://app.rintintin-pro.com" target="_blank" rel="noopener noreferrer">
          <button className="btn outline">{texts.iAmAPro}</button>
        </a>
        {isAuthenticated ? (
          <div className="user-menu">
            <img
              src={user?.profile_photo_url || 'https://img.freepik.com/vecteurs-premium/photo-profil-avatar-homme-illustration-vectorielle_268834-538.jpg'}
              alt="Profile"
              className="profile-pic"
            />
            <div className="dropdown">
              <button className="dropbtn">☰</button>
              <div className="dropdown-content">
                <Link to="/profile">Mon profil</Link>
                <Link to="/my-dog">Mon chien</Link>
                <Link to="/reservations">Mes réservations</Link>
                <Link to="/mes-forfaits">Mes forfaits</Link>
                <button onClick={logout}>Se déconnecter</button>
              </div>
            </div>
          </div>
        ) : (
          <Link to="/login">
            <button className="btn filled">{texts.login}</button>
          </Link>
        )}
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            className="menu-content"
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
          >
            {isAuthenticated ? (
              <>
                <motion.div className="menu-item" variants={menuItemVariants}>
                  <a href="https://app.rintintin-pro.com" target="_blank" rel="noopener noreferrer" onClick={toggleMenu}>
                    {texts.iAmAPro}
                  </a>
                </motion.div>
                <motion.div className="menu-item" variants={menuItemVariants}>
                  <Link to="/profile" onClick={toggleMenu}>Mon profil</Link>
                </motion.div>
                <motion.div className="menu-item" variants={menuItemVariants}>
                  <Link to="/my-dog" onClick={toggleMenu}>Mon chien</Link>
                </motion.div>
                <motion.div className="menu-item" variants={menuItemVariants}>
                  <Link to="/reservations" onClick={toggleMenu}>Mes réservations</Link>
                </motion.div>
                <motion.div className="menu-item" variants={menuItemVariants}>
                  <Link to="/mes-forfaits" onClick={toggleMenu}>Mes forfaits</Link>
                </motion.div>
                <motion.div className="menu-item" variants={menuItemVariants}>
                  <Link to="/about" onClick={toggleMenu}>À propos</Link>
                </motion.div>
                <motion.div
                  className="menu-item logout"
                  variants={menuItemVariants}
                  onClick={() => { logout(); toggleMenu(); }}
                >
                  <button className="btn link-button">Se déconnecter</button>
                </motion.div>
              </>
            ) : (
              <>
                <motion.div className="menu-item" variants={menuItemVariants}>
                  <Link to="/login" onClick={toggleMenu}>{texts.login}</Link>
                </motion.div>
                <motion.div className="menu-item" variants={menuItemVariants}>
                  <a
                    href="https://app.rintintin-pro.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={toggleMenu}
                    className="external-link"
                  >
                    {texts.iAmAPro}
                  </a>
                </motion.div>
                <motion.div className="menu-item" variants={menuItemVariants}>
                  <a
                    href="https://d14e5fa6.sibforms.com/serve/MUIFAD207ccX5oMui0at721djKI_keyFtoeBXBcBdV7lKP1HJoAE258rc0PbUxAGrz2fzLjgtRdyT_H4Y1HvPNGdvVJ0esP_CMozTgN1QW6vmYpsHE0pPQt5J1Xc6D9GiKDIx7Jjjh7oN_M5SK016tUKkCGlbr1RRtSqO6sYj7nHXhMqKyDqKWiFlFUkHTLbya2VsJ6OHCbH7gR0"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={toggleMenu}
                    className="external-link"
                  >
                    Newsletter
                  </a>
                </motion.div>
                <motion.div className="menu-item" variants={menuItemVariants}>
                  <Link to="/about" onClick={toggleMenu}>À propos</Link>
                </motion.div>
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navbar;
