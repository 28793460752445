import React, { createContext, useState, useContext, useEffect, ReactNode, useCallback } from 'react';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

interface User {
  id: number;
  user_id: number; // Si nécessaire
  email: string;
  firstName: string;
  lastName: string;
  profile_photo_url: string;
}


interface AuthContextType {
  user: User;
  login: (token: string, user: User) => void;
  logout: () => void;
  isAuthenticated: boolean;
  forceUpdate: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [, setUpdate] = useState(0);
  const navigate = useNavigate();

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setUser(null);
    setIsAuthenticated(false);
    setUpdate(prev => prev + 1);
    navigate('/login');
  }, [navigate]);

  const fetchProfile = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/get-profile`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data);  // stocke les informations complètes du profil, y compris profile_photo_url
          setIsAuthenticated(true);
        } else {
          console.error('Failed to fetch profile:', response.statusText);
          logout();
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
        logout();
      }
    }
  }, [logout]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log("Token récupéré depuis localStorage:", token); // Vérifiez que le token est bien récupéré

    if (token) {
      try {
        const decoded = jwtDecode<JwtPayload>(token);
        if (decoded.exp && decoded.exp * 1000 > Date.now()) {
          fetchProfile(); // récupère les informations complètes du profil après décodage du token
        } else {
          logout();
        }
      } catch (error) {
        console.error('Invalid token:', error);
        logout();
      }
    }
  }, [fetchProfile, logout]);


  const login = (token: string) => {
    localStorage.setItem('token', token);
    fetchProfile(); // Récupère le profil après la connexion
    setIsAuthenticated(true);
    setUpdate(prev => prev + 1);
  };

  const forceUpdate = () => {
    setUpdate(prev => prev + 1);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticated, forceUpdate }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
