import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import './MesForfaitsPage.css';

/** Structure renvoyée par l'API pour chaque service inclus dans le forfait */
interface ServiceUsage {
  service_id: number;
  service_name: string;
  included: number;
  used: number;
  remaining: number;
}

/** Informations basiques sur le formateur (trainer) */
interface TrainerInfo {
  slug?: string;  // ex: "john-doe"
  name?: string;  // ex: "John Doe"
}

/** Structure d'un forfait complet tel que renvoyé par /user-forfaits/{user_id} */
interface Forfait {
  user_forfait_id: number;
  forfait_id: number;
  forfait_name: string;
  description?: string;
  status: string;               // "active", "expired", etc.
  price?: number;               // si besoin
  purchase_date?: string;       // si besoin
  remaining_sessions?: number;  // si besoin

  trainer?: TrainerInfo;
  services?: ServiceUsage[];
}

/** Tableau affichant la liste des services inclus */
const ListeServicesForfait: React.FC<{ services: ServiceUsage[] }> = ({ services }) => {
  if (!services || services.length === 0) {
    return <div className="no-services">Aucun service inclus.</div>;
  }
  return (
    <table className="services-table">
      <thead>
        <tr>
          <th>Service</th>
          <th>Inclus</th>
          <th>Utilisés</th>
          <th>Restant</th>
        </tr>
      </thead>
      <tbody>
        {services.map((svc) => (
          <tr key={svc.service_id}>
            <td>{svc.service_name}</td>
            <td>{svc.included}</td>
            <td>{svc.used}</td>
            <td>{svc.remaining}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

/** Badge coloré selon le status du forfait */
const StatusBadge: React.FC<{ status: string }> = ({ status }) => {
  const statusClass = status.toLowerCase().replace(/\s+/g, '_'); 
  // ex: "used up" => "used_up"

  return (
    <span className={`status-badge ${statusClass}`}>
      {status}
    </span>
  );
};

/** Carte affichant un forfait */
const ForfaitCard: React.FC<{ forfait: Forfait }> = ({ forfait }) => {
  return (
    <div className="forfait-card">
      <div className="card-header">
        <h2>{forfait.forfait_name}</h2>
        {/* Petit badge de statut si besoin */}
        {forfait.status && <StatusBadge status={forfait.status} />}
      </div>

      {/* Description du forfait, si elle existe */}
      {forfait.description && (
        <p className="description">{forfait.description}</p>
      )}

      {/* Lien vers le formateur, si on a un trainer.slug */}
      {forfait.trainer?.slug && forfait.trainer.name && (
        <div className="trainer-item">
          <span>Éducateur : </span>
          <Link to={`/pro/${forfait.trainer.slug}`} className="trainer-link">
            {forfait.trainer.name}
          </Link>
        </div>
      )}

      {/* Liste des services inclus */}
      {forfait.services && forfait.services.length > 0 && (
        <div className="services-section">
          <h4>Services inclus</h4>
          <ListeServicesForfait services={forfait.services} />
        </div>
      )}
    </div>
  );
};

const MesForfaitsPage: React.FC = () => {
  const { user } = useAuth();
  const [forfaits, setForfaits] = useState<Forfait[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchForfaits = async () => {
      try {
        if (!user) return;
        const token = localStorage.getItem('token');
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/user-forfaits/${user.user_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.data.forfaits) {
          setForfaits(response.data.forfaits);
        }
      } catch (err) {
        setError('Erreur lors du chargement de vos forfaits');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchForfaits();
  }, [user]);

  return (
    <div className="forfaits-page-container">
      <div className="forfaits-header">
        <h1>Mes Forfaits</h1>
      </div>

      <div className="forfaits-content">
        {/* Si pas connecté */}
        {!user && (
          <p className="auth-message">
            Vous devez être connecté pour consulter vos forfaits.
          </p>
        )}

        {/* Loader */}
        {user && loading && (
          <div className="loading-spinner" />
        )}

        {/* Erreur */}
        {user && !loading && error && (
          <p className="error-message">{error}</p>
        )}

        {/* Liste de forfaits */}
        {user && !loading && !error && (
          <div className="forfaits-list">
            {forfaits.length === 0 ? (
              <p className="empty-message">Vous n'avez pas encore de forfait.</p>
            ) : (
              forfaits.map((forfait) => (
                <ForfaitCard key={forfait.user_forfait_id} forfait={forfait} />
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MesForfaitsPage;
