/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// ProfessionalList.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SearchBar from '../../components/SearchBar/SearchBar';
import TrainerCard from '../../components/TrainerCard/TrainerCard';
import {
  Box,
  Button,
  Typography,
  Grid,
  CircularProgress,
  ThemeProvider,
  createTheme,
  CssBaseline,
} from '@mui/material';

interface Professional {
  id: number;
  slug: string;
  name: string;
  description: string;
  profile_photo_url: string;
  ville: string;
  rating: number;
  education_methods: string[];
  specialties: string[];
  services: string[];
  is_professional: boolean;
  lat?: number;
  lon?: number;
  distance_km?: number;
}

interface Profession {
  id: number;
  name: string;
  slug: string;
}

// Mapping des descriptions par catégorie
const categoryDescriptions: { [key: string]: string } = {
  'pet-sitter': 'Les Pet sitters s’occupent de vos animaux de compagnie en votre absence, garantissant leur confort et leur sécurité.',
  'educateur-canin': 'Les éducateurs canins vous accompagnent dans l’éducation et le bien-être de votre chien avec expertise et passion.',
  'comportementaliste': 'Les comportementalistes interviennent pour résoudre les problèmes comportementaux de vos animaux et améliorer leur relation avec vous.',
  // Ajoute d'autres descriptions selon tes métiers...
};

const genericDescription =
  "Découvrez nos professionnels experts dans divers domaines, prêts à vous accompagner et à répondre à vos besoins.";

// Affichage des étoiles pour la note
function renderStars(rating: number = 0) {
  try {
    if (typeof rating !== 'number' || isNaN(rating)) {
      rating = 0;
    }
    const fullStars = Math.floor(rating);
    const halfStar = rating - fullStars >= 0.5;
    const stars: string[] = [];
    for (let i = 0; i < fullStars; i++) {
      stars.push('★');
    }
    if (halfStar) stars.push('☆');
    return (
      <span style={{ color: '#ffc107', fontSize: '1.1rem', marginLeft: '0.5rem' }}>
        {stars.join('')}
      </span>
    );
  } catch (error) {
    console.error("Error in renderStars:", error);
    return null;
  }
}

// Thème MUI avec une touche golden retriever et surcharge pour les boutons outlined
const theme = createTheme({
  palette: {
    primary: {
      main: '#F6C674', // doré chaleureux
    },
    secondary: {
      main: '#333',
    },
    background: {
      default: '#f9f9f9',
      paper: '#fafafa',
    },
    text: {
      primary: '#333',
      secondary: '#555',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          borderColor: 'black',
          color: 'black',
          '&:hover': {
            borderColor: 'black',
          },
        },
      },
    },
  },
});

const ProfessionalList: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Récupération des paramètres d'URL
  const address = searchParams.get('address') || '';
  const selectedProfession = searchParams.get('profession') || '';
  const quoi = searchParams.get('quoi') || ''; // Le nom affichable du métier

  // Définir le libellé à afficher
  const displayProfession =
    quoi || (selectedProfession ? selectedProfession.charAt(0).toUpperCase() + selectedProfession.slice(1) : '');

  // Définir la description à afficher
  const descriptionText = selectedProfession
    ? (categoryDescriptions[selectedProfession] || '')
    : genericDescription;

  // États pour la liste des professionnels et des métiers
  const [professionals, setProfessionals] = useState<Professional[]>([]);
  const [professions, setProfessions] = useState<Profession[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Récupération de la liste des métiers depuis l'API
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/get-professions`)
      .then(response => {
        console.log("Métiers reçus :", response.data.professions);
        setProfessions(response.data.professions);
      })
      .catch(err => {
        console.error('Erreur lors de la récupération des métiers', err);
      });
  }, []);

  // Fonction pour récupérer les professionnels (filtrage via address et profession)
  const fetchProfessionals = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-trainers`, {
        params: { address, profession: selectedProfession },
      });
      setProfessionals(response.data.trainers);
    } catch (err) {
      setError("Une erreur est survenue lors du chargement des professionnels.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfessionals();
  }, [address, selectedProfession]);

  // Mise à jour du filtre dans l'URL via les boutons de filtrage
  const handleFilterChange = (professionSlug: string, professionName: string) => {
    const params = new URLSearchParams(searchParams);
    if (professionSlug) {
      params.set('profession', professionSlug);
      params.set('quoi', professionName);
    } else {
      params.delete('profession');
      params.delete('quoi');
    }
    setSearchParams(params);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          padding: { xs: '16px', md: '40px' },
          backgroundColor: 'background.default',
          minHeight: '100vh',
        }}
      >
        <SearchBar />

        {/* Barre de filtres */}
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginY: 2 }}>
          <Button
            variant={!selectedProfession ? 'contained' : 'outlined'}
            onClick={() => handleFilterChange('', '')}
            sx={{ textTransform: 'none', borderRadius: 2 }}
          >
            Tous les professionnels
          </Button>
          {professions.map(prof => (
            <Button
              key={prof.id}
              variant={selectedProfession === prof.slug ? 'contained' : 'outlined'}
              onClick={() => handleFilterChange(prof.slug, prof.name)}
              sx={{ textTransform: 'none', borderRadius: 2 }}
            >
              {prof.name}
            </Button>
          ))}
        </Box>

        {/* Description de la catégorie */}
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
            {displayProfession || "Tous les professionnels"}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {descriptionText}
          </Typography>
        </Box>

        {/* Contenu principal */}
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <CircularProgress size={50} color="primary" />
          </Box>
        ) : error ? (
          <Typography variant="body1" color="error">
            {error}
          </Typography>
        ) : professionals.length === 0 ? (
          <Typography variant="body1" color="text.secondary">
            Désolé, aucun professionnel {displayProfession && `(${displayProfession})`} trouvé.
          </Typography>
        ) : (
          <Grid container spacing={3}>
            {professionals.map(pro => (
              <Grid item xs={12} sm={6} md={4} key={pro.id}>
                <TrainerCard
                  slug={pro.slug}
                  profilePhotoUrl={pro.profile_photo_url}
                  name={pro.name}
                  ville={pro.ville}
                  description={pro.description}
                  isProfessional={pro.is_professional}
                  ratingContent={renderStars(pro.rating)}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default ProfessionalList;
