// src/components/Footer/Footer.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { SiTiktok, SiFacebook, SiInstagram } from 'react-icons/si';
import './Footer.css';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer" role="contentinfo">
      <div className="footer-content">
        {/* Zone supérieure : Copyright + réseaux sociaux */}
        <div className="footer-top">
          <p>&copy; {currentYear} RINTINTIN. Tous droits réservés.</p>
          <div className="social-media">
            <a
              href="https://www.facebook.com/profile.php?id=61572253926234"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <SiFacebook />
            </a>
            <a
              href="https://www.instagram.com/rintintin_pets"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <SiInstagram />
            </a>
            <a
              href="https://www.tiktok.com/@rintintin_pets"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="TikTok"
            >
              <SiTiktok />
            </a>
          </div>
        </div>

        {/* Zone inférieure : Liens de navigation */}
        <div className="footer-links-container">
          <nav className="footer-links" aria-label="Footer Navigation">
            <Link to="/about">À propos de nous</Link>
            <Link to="/login">Se connecter</Link>
            <Link to="/signup">Créer un compte</Link>
            <Link to="/legal">Mentions légales</Link>
            <Link to="/terms-of-use">Conditions d'utilisation</Link>
            <Link to="/privacy-policy">Politique de confidentialité</Link>
            <a
              href="https://rintintin-pro.com/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              Je suis un professionnel
            </a>
            <Link to="/contact">Nous contacter</Link>
            <Link to="/cgv">Conditions Générales de Vente</Link>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
