/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import AddDogModal from '../../modals/AddDogModal';
import { SiTiktok } from 'react-icons/si';


import {
  Card,
  CardContent,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Grid,
  Box,
  IconButton,
  Tooltip,
  useMediaQuery,
  createTheme,
  ThemeProvider,
  CssBaseline,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Tabs,
  Tab,
} from '@mui/material';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SelectChangeEvent } from '@mui/material';
import { FiArrowLeft } from 'react-icons/fi';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import TimePickerComponent from '../../components/TimePickerComponent.tsx/TimePickerComponent';
import DatePickerComponent from '../../components/DatePickerComponent/DatePickerComponent';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { AccessTime } from '@mui/icons-material';
import CGVModal from '../../modals/CGVModal';

const iconMap: { [key: string]: JSX.Element } = {
  Facebook: <FacebookIcon />,
  Instagram: <InstagramIcon />,
  LinkedIn: <LinkedInIcon />,
  Twitter: <TwitterIcon />,
  TikTok: <SiTiktok size={24} color="#000" />,
};

// ----------------- Interfaces -----------------
interface Contact {
  network_name: string;
  network_link: string;
}

interface Trainer {
  id: number;
  name: string;
  description: string;
  profile_photo_url: string;
  education_methods: string[];
  specialties: string[];
  photos: string[];
  email: string;
  ville: string;
  is_professional: boolean;
  contacts?: Contact[];
  cgv?: string;
}

interface Dog {
  dogId: number;
  userId: number;
  name: string;
  breed: string;
  profilePhotoUrl: string;
  information: string;
  birthdate: string;
}

interface TrainerService {
  service_id: number;
  service_name: string;
  price: number;
  duration: number;
  location: string;
  question: string;
  description: string;
  hasAvailabilities?: boolean;
  allow_online_payment?: boolean;
  hasForfaitDisponible?: boolean;
  visible: boolean;
}

interface ForfaitService {
  service_name: string;
  price: number;
  quantity: number;
}

interface Forfait {
  forfait_id: number;
  name: string;
  description: string;
  price: number;
  services?: ForfaitService[];
}

const steps = [
  'Choisir un service',
  'Choisir une date',
  'Choisir une heure',
  'Choisir un chien',
  'Confirmer la réservation',
];

// =====================
// ==== MUI THEME  =====
// =====================
const theme = createTheme({
  palette: {
    primary: {
      main: '#F6C674',
    },
    secondary: {
      main: '#333',
    },
    success: {
      main: '#5cb85c',
    },
    warning: {
      main: '#f0ad4e',
    },
    background: {
      default: '#f9f9f9',
      paper: '#fafafa',
    },
    text: {
      primary: '#333',
      secondary: '#555',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h4: { fontWeight: 'bold' },
    h5: { fontWeight: 'bold' },
  },
});

// =======================
// ==== STYLED COMPONENTS (onglets personnalisés) =====
// =======================
const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main,
    height: 3,
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 'bold',
  minWidth: 100,
  [theme.breakpoints.up('sm')]: {
    minWidth: 120,
  },
  '&.Mui-selected': {
    color: theme.palette.primary.main,
  },
}));

const StyledButton = styled(Button)(() => ({
  textTransform: 'none',
  padding: 0,
  minWidth: 0,
}));

// ----------------- Composant principal TrainerDetail -----------------
const TrainerDetail: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams<{ slug?: string }>();
  const [searchParams] = useSearchParams();
  const { isAuthenticated, user } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [trainer, setTrainer] = useState<Trainer | null>(null);
  const [loading, setLoading] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const defaultBanner = require('../../assets/default-banner.png');

  // Onglet "Services"
  const [servicesTrainer, setServicesTrainer] = useState<TrainerService[]>([]);
  const [dogs, setDogs] = useState<Dog[]>([]);
  const [selectedDog, setSelectedDog] = useState<number | null>(null);
  const [isAddDogModalOpen, setIsAddDogModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState<TrainerService | null>(null);
  const [clientAnswer, setClientAnswer] = useState<string>('');
  const [expandedDescriptions, setExpandedDescriptions] = useState<{ [key: number]: boolean }>({});

  // Dispos
  const [availableDatesByService, setAvailableDatesByService] = useState<{ [key: number]: string[] }>({});
  const [selectedDateByService, setSelectedDateByService] = useState<{ [key: number]: string }>({});
  const [availableTimesByService, setAvailableTimesByService] = useState<{ [key: number]: string[] }>({});
  const [selectedTimeByService, setSelectedTimeByService] = useState<{ [key: number]: string }>({});
  const [calendarOpenForService, setCalendarOpenForService] = useState<number | null>(null);
  const [loadingServices, setLoadingServices] = useState(false);
  const [validForfaits, setValidForfaits] = useState<any[]>([]);

  const [isReserving, setIsReserving] = useState(false);
  const reservationCardRef = useRef<HTMLDivElement>(null);

  // Onglet "Forfaits"
  const [trainerForfaits, setTrainerForfaits] = useState<Forfait[]>([]);
  const [loadingForfaits, setLoadingForfaits] = useState(false);

  // Tabs
  const [currentTab, setCurrentTab] = useState<'services' | 'forfaits'>('services');
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue as 'services' | 'forfaits');
  };

  // CGV Modal
  const [isCGVModalOpen, setIsCGVModalOpen] = useState(false);

  // Check URL parameter on component mount
  useEffect(() => {
    if (searchParams.get('cgv') === 'true' && trainer?.cgv) {
      setIsCGVModalOpen(true);
    }
  }, [searchParams, trainer?.cgv]);

  // =======================
  // ===== USE EFFECTS =====
  // =======================
	useEffect(() => {
		if (!isAuthenticated){
			localStorage.setItem('redirectUrl', window.location.pathname);
			return ;
		}
	}, [isAuthenticated])


  // Récup du formateur + services
  useEffect(() => {
    if (!slug) return;
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/get-trainer-by-slug/${slug}`)
      .then((res) => {
        setTrainer(res.data);
        setLoading(false);

        // Récup services
        const trainerId = res.data.id;
        setLoadingServices(true);

        axios
          .get(`${process.env.REACT_APP_API_BASE_URL}/get-services/${trainerId}`)
          .then(async (serviceResponse) => {
            // Récupérer tous les services puis ne garder que ceux dont visible est true
            const allServices: TrainerService[] = serviceResponse.data.services;
            const visibleServices = allServices.filter((s) => s.visible);

            // 1) Vérifier la disponibilité sur les services visibles
            const servicesWithAvailability = await Promise.all(
              visibleServices.map(async (s) => {
                try {
                  const availabilityRes = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/get-available-dates/${s.service_id}`
                  );
                  return {
                    ...s,
                    hasAvailabilities: availabilityRes.data.availableDates.length > 0,
                  };
                } catch {
                  return { ...s, hasAvailabilities: false };
                }
              })
            );

            // 2) Si user connecté, check si un forfait existe pour chaque service
            if (user && user.user_id) {
              const updatedServices = await Promise.all(
                servicesWithAvailability.map(async (svc) => {
                  try {
                    const fRes = await axios.get(
                      `${process.env.REACT_APP_API_BASE_URL}/get-valid-forfaits`,
                      {
                        params: { user_id: user.user_id, service_id: svc.service_id },
                        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                      }
                    );
                    const ffRaw = fRes.data.forfaits || [];
                    const ff = ffRaw.filter((f: any) => f.remaining > 0);
                    return {
                      ...svc,
                      hasForfaitDisponible: ff.length > 0,
                    };
                  } catch (err) {
                    console.error('Erreur check forfait service', svc.service_id, err);
                    return svc;
                  }
                })
              );
              setServicesTrainer(updatedServices);
            } else {
              setServicesTrainer(servicesWithAvailability);
            }

            setLoadingServices(false);
          })
          .catch((error) => {
            console.error('Erreur récupération services:', error);
            setLoadingServices(false);
          });
      })
      .catch((error) => {
        console.error('Erreur récupération formateur:', error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [slug, user]);


  // Récup des chiens
  useEffect(() => {
    if (isAuthenticated && user && user.user_id) {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/get-dogs/${user.user_id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        })
        .then((res) => {
          setDogs(res.data.dogs);
        })
        .catch((err) => {
          console.error('Erreur récupération chiens:', err);
          toast.error('Erreur récupération de vos chiens.');
        });
    }
  }, [isAuthenticated, user]);

  // Récup des forfaits du pro
  useEffect(() => {
    if (trainer && trainer.id) {
      setLoadingForfaits(true);
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/get-forfaits/${trainer.id}`)
        .then((res) => {
          const allForfaits = res.data.forfaits || [];
          const activeForfaits = allForfaits.filter((f: { status: string }) => f.status !== 'archived');
          setTrainerForfaits(activeForfaits);
        })
        .catch((err) => {
          console.error('Erreur récupération forfaits:', err);
          toast.error('Erreur récupération forfaits.');
        })
        .finally(() => {
          setLoadingForfaits(false);
        });
    }
  }, [trainer]);

  // ============================================
  // ======== Fonctions pour la réservation =====
  // ============================================
  // Expand description
  const toggleDescription = (serviceId: number) => {
    setExpandedDescriptions((prev) => ({ ...prev, [serviceId]: !prev[serviceId] }));
  };

  // Clic sur "Voir les disponibilités"
  const handleViewSlots = async (service: TrainerService) => {
    setCalendarOpenForService(service.service_id);
    setSelectedService(service);

    // reset date/time
    setSelectedDateByService((prev) => ({ ...prev, [service.service_id]: '' }));
    setAvailableTimesByService((prev) => ({ ...prev, [service.service_id]: [] }));
    setSelectedTimeByService((prev) => ({ ...prev, [service.service_id]: '' }));

    try {
      const resp = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-available-dates/${service.service_id}`);
      setAvailableDatesByService((prev) => ({
        ...prev,
        [service.service_id]: resp.data.availableDates,
      }));
    } catch (err) {
      console.error('Erreur récupération dates:', err);
      toast.error('Erreur récupération dates.');
    }
  };

  const handleDateSelect = async (date: string, serviceId: number) => {
    setSelectedDateByService((prev) => ({ ...prev, [serviceId]: date }));
    setAvailableTimesByService((prev) => ({ ...prev, [serviceId]: [] }));
    setSelectedTimeByService((prev) => ({ ...prev, [serviceId]: '' }));

    try {
      const resp = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-available-times-improved`, {
        params: { service_id: serviceId, date },
      });
      setAvailableTimesByService((prev) => ({
        ...prev,
        [serviceId]: resp.data.availableTimes,
      }));
    } catch (err) {
      console.error('Erreur récupération créneaux:', err);
      toast.error('Erreur récupération créneaux.');
    }
  };

  const handleTimeSelect = (time: string, serviceId: number) => {
    setSelectedTimeByService((prev) => ({ ...prev, [serviceId]: time }));
    // Scroll si mobile
    if (isMobile && reservationCardRef.current) {
      reservationCardRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleDogSelect = (event: SelectChangeEvent) => {
    setSelectedDog(Number(event.target.value));
  };

  // Paiement en ligne
  const handleOnlinePayment = async () => {
    if (isReserving) return;
    setIsReserving(true);

    if (!selectedService || !selectedDateByService[selectedService.service_id] || !selectedTimeByService[selectedService.service_id] || !selectedDog) {
      toast.warning('Sélectionnez un service, une date, une heure et un chien.');
      setIsReserving(false);
      return;
    }

    if (selectedService.question && !clientAnswer.trim()) {
      toast.warning('Veuillez répondre à la question.');
      setIsReserving(false);
      return;
    }

    try {
      const resData = {
        trainer_id: trainer?.id,
        service_id: selectedService.service_id,
        date: selectedDateByService[selectedService.service_id],
        start_time: selectedTimeByService[selectedService.service_id],
        dog_id: selectedDog,
        client_answer: clientAnswer || null,
      };
      const initiate = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/initiate-booking`, resData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      const booking_id = initiate.data.booking_id;
      const checkoutData = {
        booking_id,
        user_id: user?.user_id,
      };
      const checkoutRes = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`, checkoutData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      const { url } = checkoutRes.data;
      if (url) {
        localStorage.setItem('currentBookingId', booking_id.toString());
        window.location.href = url;
      } else {
        toast.error("Impossible d'obtenir l'URL de paiement");
      }
    } catch (err) {
      console.error('Erreur create-checkout-session:', err);
      toast.error('Erreur création session de paiement.');
    } finally {
      setIsReserving(false);
    }
  };

  // Réserver via forfait
  async function createBookingWithForfait(userForfaitId: number) {
    if (isReserving) return;
    setIsReserving(true);

    if (!selectedService) {
      toast.warning('Sélectionnez un service.');
      setIsReserving(false);
      return;
    }

    const svcId = selectedService.service_id;
    const selDate = selectedDateByService[svcId];
    const selTime = selectedTimeByService[svcId];

    if (!selDate || !selTime || !selectedDog) {
      toast.warning('Sélectionnez date, heure, chien.');
      setIsReserving(false);
      return;
    }

    if (selectedService.question && !clientAnswer.trim()) {
      toast.warning('Veuillez répondre à la question.');
      setIsReserving(false);
      return;
    }

    try {
      const data = {
        user_id: user?.user_id,
        trainer_id: trainer?.id,
        service_id: svcId,
        date: selDate,
        start_time: selTime,
        dog_id: selectedDog,
        client_answer: clientAnswer || null,
        use_forfait_id: userForfaitId,
      };
      const resp = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/create-booking`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      const bookingId = resp.data.booking_id;
      toast.success('Réservation effectuée via votre forfait !');

      // Reset
      setSelectedDateByService((prev) => ({ ...prev, [svcId]: '' }));
      setAvailableTimesByService((prev) => ({ ...prev, [svcId]: [] }));
      setSelectedTimeByService((prev) => ({ ...prev, [svcId]: '' }));
      setSelectedDog(null);
      setClientAnswer('');

      navigate(`/booking-confirmation?booking_id=${bookingId}`);
    } catch (err) {
      console.error('Erreur réservation forfait:', err);
      toast.error('Erreur réservation via forfait.');
    } finally {
      setIsReserving(false);
    }
  }

  // Vérifier existence d'un forfait
  async function checkAndUseForfait() {
    if (!user) {
      toast.error('Vous devez être connecté pour utiliser un forfait.');
      return [];
    }
    if (!selectedService) {
      toast.error('Sélectionnez un service.');
      return [];
    }

    try {
      const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-valid-forfaits`, {
        params: { user_id: user.user_id, service_id: selectedService.service_id },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      const validF = (res.data.forfaits || []).filter((f: any) => f.remaining > 0);

      // On stocke en state si besoin pour l'affichage
      setValidForfaits(validF);
      return validF;
    } catch (err) {
      console.error('Erreur fetch forfaits valides:', err);
      return [];
    }
  }

  // Bouton final "Confirmer la réservation"
  const handleConfirmReservation = async () => {
    if (!selectedService) {
      toast.warning("Veuillez sélectionner un service.");
      return;
    }

    // Vérifier si un forfait existe
    const userForfaits = await checkAndUseForfait();
    if (userForfaits.length > 0) {
      // Ici, on propose par ex un confirm
      const chosenForfait = userForfaits[0];
      const useIt = window.confirm(
        `Vous avez un forfait "${chosenForfait.name}" (reste ${chosenForfait.remaining} séances). L'utiliser ?`
      );
      if (useIt) {
        return createBookingWithForfait(chosenForfait.user_forfait_id);
      }
    }

    // Sinon flux normal
    if (selectedService.allow_online_payment) {
      handleOnlinePayment();
    } else {
      handleReservation();
    }
  };

  // Réserver (paiement sur place)
  const handleReservation = async () => {
    if (isReserving) return;
    setIsReserving(true);

    if (!selectedService) {
      toast.warning('Sélectionnez un service.');
      setIsReserving(false);
      return;
    }
    const svcId = selectedService.service_id;
    const selDate = selectedDateByService[svcId];
    const selTime = selectedTimeByService[svcId];

    if (!selDate || !selTime || !selectedDog) {
      toast.warning('Sélectionnez date, heure et chien.');
      setIsReserving(false);
      return;
    }

    if (selectedService.question && !clientAnswer.trim()) {
      toast.warning('Veuillez répondre à la question.');
      setIsReserving(false);
      return;
    }

    try {
      const data = {
        user_id: user?.user_id,
        trainer_id: trainer?.id,
        service_id: svcId,
        date: selDate,
        start_time: selTime,
        dog_id: selectedDog,
        client_answer: clientAnswer || null,
      };

      const resp = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/create-booking`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      const bookingId = resp.data.booking_id;

      toast.success('Réservation effectuée avec succès !');

      // reset
      setSelectedDateByService((prev) => ({ ...prev, [svcId]: '' }));
      setAvailableTimesByService((prev) => ({ ...prev, [svcId]: [] }));
      setSelectedTimeByService((prev) => ({ ...prev, [svcId]: '' }));
      setSelectedDog(null);
      setClientAnswer('');

      navigate(`/booking-confirmation?booking_id=${bookingId}`);
    } catch (err) {
      console.error('Erreur réservation:', err);
      toast.error('Erreur réservation.');
    } finally {
      setIsReserving(false);
    }
  };

  // ========== Achat forfait ==========
  const handlePurchaseForfait = async (forfait_id: number) => {
    if (!isAuthenticated || !user?.user_id) {
      toast.error('Veuillez vous connecter pour acheter un forfait.');
      return;
    }
    try {
      // 1) on enregistre localement l'ID du forfait
      localStorage.setItem('pendingForfait', JSON.stringify({ forfait_id }));

      const data = { user_id: user.user_id, forfait_id };
      const resp = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/purchase-forfait`,
        data,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      const { url } = resp.data;
      if (url) {
        window.location.href = url;
      } else {
        toast.error("Impossible d'obtenir l'URL de paiement Stripe.");
      }
    } catch (err) {
      console.error('Erreur creation session forfait:', err);
      toast.error('Erreur lors de la création de la session.');
    }
  };

  // ========== Divers ==========
  const handleBackButtonClick = () => navigate(-1);
  const nextImage = () => {
    if (trainer && trainer.photos.length > 1) {
      setCurrentImageIndex((prev) => (prev + 1) % trainer.photos.length);
    }
  };
  const prevImage = () => {
    if (trainer && trainer.photos.length > 1) {
      setCurrentImageIndex((prev) => (prev - 1 + trainer.photos.length) % trainer.photos.length);
    }
  };
  const openAddDogModal = () => setIsAddDogModalOpen(true);
  const closeAddDogModal = () => setIsAddDogModalOpen(false);

  // Stepper (services)
  let activeStep = 0;
  if (selectedService) activeStep = 1;
  const serviceId = selectedService?.service_id || 0;
  if (selectedDateByService[serviceId]) activeStep = 2;
  if (selectedTimeByService[serviceId]) activeStep = 3;
  if (selectedDog) activeStep = 4;

  const descriptionWithBr = trainer?.description.replace(/\n/g, '<br/>') || '';

  const renderPaymentIndicator = (service: TrainerService) => {
    if (service.allow_online_payment) {
      return (
        <Tooltip title="Ce service se paie en ligne lors de la réservation">
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', mt: 1 }}>
            <MonetizationOnIcon style={{ color: '#5cb85c' }} />
            <Typography variant="body2" sx={{ color: '#5cb85c', fontWeight: 'bold' }}>
              Paiement en ligne
            </Typography>
          </Box>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Ce service se paie sur place, le jour du rendez-vous">
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', mt: 1 }}>
            <MonetizationOnIcon style={{ color: '#f0ad4e' }} />
            <Typography variant="body2" sx={{ color: '#f0ad4e', fontWeight: 'bold' }}>
              Paiement sur place
            </Typography>
          </Box>
        </Tooltip>
      );
    }
  };

  // ============= RENDU PRINCIPAL =============
  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
          <Typography variant="h6">Chargement...</Typography>
        </Box>
      </ThemeProvider>
    );
  }

  if (!trainer) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
          <Typography variant="h6">Éducateur non trouvé</Typography>
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      {/* -------- Image de couverture + bouton retour -------- */}
      <Box sx={{ position: 'relative', mb: 4, borderRadius: '10px', overflow: 'hidden' }}>
          <>
            <img
              src={trainer.photos[currentImageIndex] || defaultBanner}
              alt={`Trainer ${trainer.name}`}
              style={{ width: '100%', height: '400px', objectFit: 'cover', objectPosition: 'center' }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0,0,0,0.35)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                p: 3,
              }}
            >
              <Typography variant="h4" sx={{ color: '#fff', fontWeight: 'bold', mb: 1 }}>
                {trainer.name}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#fff', mb: 2 }}>
                📍 {trainer.ville}
              </Typography>
            </Box>

            {trainer.photos.length > 1 && (
              <>
                <IconButton
                  onClick={prevImage}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '10px',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' },
                    color: '#fff',
                  }}
                >
                  <FiArrowLeft size={20} />
                </IconButton>
                <IconButton
                  onClick={nextImage}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    right: '10px',
                    transform: 'translateY(-50%) rotate(180deg)',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' },
                    color: '#fff',
                  }}
                >
                  <FiArrowLeft size={20} />
                </IconButton>
              </>
            )}

            <IconButton
              onClick={handleBackButtonClick}
              sx={{
                position: 'absolute',
                top: '20px',
                left: '20px',
                backgroundColor: 'rgba(0,0,0,0.5)',
                color: '#fff',
                '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' },
              }}
            >
              <FiArrowLeft size={24} />
            </IconButton>
          </>
      </Box>

      {/* -------- Contenu principal -------- */}
      <Box sx={{ padding: { xs: '10px', md: '40px' }, backgroundColor: 'background.default', minHeight: '100vh' }}>
        <Grid container spacing={4}>
          {/* =========== Colonne principale (description, onglets) =========== */}
          <Grid item xs={12} md={8}>
            <Card sx={{ mb: 4, p: { xs: 2, md: 3 }, boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
              <CardContent>
                <Typography
                  variant="body1"
                  paragraph
                  dangerouslySetInnerHTML={{ __html: descriptionWithBr }}
                  sx={{ color: 'text.secondary', lineHeight: 1.7 }}
                />
                {trainer.contacts && trainer.contacts.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                      Réseaux du professionnel
                    </Typography>
                    <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', mt: 1 }}>
                      {trainer.contacts.map((contact, index) => {
                        const IconComponent = iconMap[contact.network_name] || <LanguageIcon />;
                        return (
                          <a
                            key={index}
                            href={contact.network_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'none', color: '#333', display: 'flex', alignItems: 'center' }}
                          >
                            {IconComponent}
                          </a>
                        );
                      })}
                    </Box>
                  </Box>
                )}
              </CardContent>
            </Card>

            {/* Si formateur professionnel */}
            {trainer.is_professional ? (
              // ============================
              // ===== 1) S'IL N'A PAS DE FORFAITS => on n'affiche que les services  =====
              // ============================
              trainerForfaits.length === 0 ? (
                <Box>
                  <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
                    Services proposés
                  </Typography>
                  {loadingServices ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <CircularProgress />
                      <Typography>Chargement des services...</Typography>
                    </Box>
                  ) : servicesTrainer.length === 0 ? (
                    <Typography variant="body1" sx={{ color: 'text.secondary', mt: 2 }}>
                      Ce professionnel n'a pas encore de services.
                    </Typography>
                  ) : (
                    <Grid container spacing={3}>
                      {servicesTrainer.map((service) => (
                        <Grid item xs={12} key={service.service_id}>
                          <Card
                            sx={{ p: 3, boxShadow: '0 2px 4px rgba(0,0,0,0.1)', borderRadius: '10px' }}
                          >
                            <CardContent>
                              <Box
                                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                              >
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  sx={{ fontWeight: 'bold', color: 'text.primary' }}
                                >
                                  {service.service_name} - {service.price}€
                                </Typography>
                                {/* S'il y a un forfait dispo => mini label */}
                                {service.hasForfaitDisponible && (
                                  <Box
                                    sx={{
                                      backgroundColor: '#ffc107',
                                      color: '#333',
                                      px: 1,
                                      py: 0.5,
                                      borderRadius: '4px',
                                      fontSize: '0.75rem',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Vous pouvez réserver ce service avec votre forfait
                                  </Box>
                                )}
                              </Box>

                              {/* Durée / Lieu */}
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
                                  <AccessTime fontSize="small" sx={{ mr: 0.5 }} />
                                  <Typography variant="body2">{service.duration} min</Typography>
                                </Box>
                              </Box>
                              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                Lieu : {service.location}
                              </Typography>

                              {renderPaymentIndicator(service)}

                              {/* Description */}
                              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                {service.description.length > 300 && !expandedDescriptions[service.service_id] ? (
                                  <>
                                    {service.description.substring(0, 300) + '... '}
                                    <StyledButton
                                      variant="text"
                                      onClick={() => toggleDescription(service.service_id)}
                                      sx={{ color: 'primary.main', fontWeight: 'bold', textTransform: 'none' }}
                                    >
                                      Voir plus
                                    </StyledButton>
                                  </>
                                ) : (
                                  <>
                                    {service.description}{' '}
                                    {service.description.length > 300 && (
                                      <StyledButton
                                        variant="text"
                                        onClick={() => toggleDescription(service.service_id)}
                                        sx={{ color: 'primary.main', fontWeight: 'bold', textTransform: 'none' }}
                                      >
                                        Voir moins
                                      </StyledButton>
                                    )}
                                  </>
                                )}
                              </Typography>

                              {/* Voir dispos */}
                              {service.hasAvailabilities ? (
                                <StyledButton
                                  variant="contained"
                                  onClick={() => handleViewSlots(service)}
                                  sx={{
                                    backgroundColor: 'secondary.main',
                                    color: '#fff',
                                    '&:hover': { backgroundColor: '#555' },
                                    mt: 2,
                                    padding: '4px 10px',
                                    fontSize: '1rem',
                                    minWidth: '200px',
                                    borderRadius: '8px',
                                  }}
                                >
                                  Voir les disponibilités
                                </StyledButton>
                              ) : (
                                <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                                  Pas de disponibilités pour le moment.
                                </Typography>
                              )}

                              {/* Calendrier */}
                              {calendarOpenForService === service.service_id && (
                                <Box sx={{ mt: 3 }}>
                                  <DatePickerComponent
                                    availableDates={availableDatesByService[service.service_id] || []}
                                    selectedDate={selectedDateByService[service.service_id] || ''}
                                    onDateSelect={(date) => handleDateSelect(date, service.service_id)}
                                  />
                                  {selectedDateByService[service.service_id] && (
                                    <TimePickerComponent
                                      availableTimes={availableTimesByService[service.service_id] || []}
                                      selectedTime={selectedTimeByService[service.service_id] || ''}
                                      onTimeSelect={(time) => handleTimeSelect(time, service.service_id)}
                                    />
                                  )}
                                </Box>
                              )}
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Box>
              ) : (
                // ============================
                // ===== 2) S'IL A DES FORFAITS => on affiche les onglets  =====
                // ============================
                <>
                  <StyledTabs value={currentTab} onChange={handleTabChange}>
                    <StyledTab label="Services" value="services" />
                    <StyledTab label="Forfaits" value="forfaits" />
                  </StyledTabs>

                  {/* ======== Onglet Services ======== */}
                  {currentTab === 'services' && (
                    <Box>
                      {loadingServices ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <CircularProgress />
                          <Typography>Chargement des services...</Typography>
                        </Box>
                      ) : servicesTrainer.length === 0 ? (
                        <Typography variant="body1" sx={{ color: 'text.secondary', mt: 2 }}>
                          Ce professionnel n'a pas encore de services.
                        </Typography>
                      ) : (
                        <Grid container spacing={3}>
                          {servicesTrainer.map((service) => (
                            <Grid item xs={12} key={service.service_id}>
                              <Card
                                sx={{ p: 3, boxShadow: '0 2px 4px rgba(0,0,0,0.1)', borderRadius: '10px' }}
                              >
                                <CardContent>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Typography
                                      variant="h6"
                                      gutterBottom
                                      sx={{ fontWeight: 'bold', color: 'text.primary' }}
                                    >
                                      {service.service_name} - {service.price}€
                                    </Typography>
                                    {service.hasForfaitDisponible && (
                                      <Box
                                        sx={{
                                          backgroundColor: '#ffc107',
                                          color: '#333',
                                          px: 1,
                                          py: 0.5,
                                          borderRadius: '4px',
                                          fontSize: '0.75rem',
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        Vous pouvez réserver ce service avec votre forfait
                                      </Box>
                                    )}
                                  </Box>

                                  {/* Durée / Lieu */}
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
                                      <AccessTime fontSize="small" sx={{ mr: 0.5 }} />
                                      <Typography variant="body2">{service.duration} min</Typography>
                                    </Box>
                                  </Box>
                                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Lieu : {service.location}
                                  </Typography>

                                  {renderPaymentIndicator(service)}

                                  {/* Description */}
                                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                    {service.description.length > 300 && !expandedDescriptions[service.service_id] ? (
                                      <>
                                        {service.description.substring(0, 300) + '... '}
                                        <StyledButton
                                          variant="text"
                                          onClick={() => toggleDescription(service.service_id)}
                                          sx={{ color: 'primary.main', fontWeight: 'bold', textTransform: 'none' }}
                                        >
                                          Voir plus
                                        </StyledButton>
                                      </>
                                    ) : (
                                      <>
                                        {service.description}{' '}
                                        {service.description.length > 300 && (
                                          <StyledButton
                                            variant="text"
                                            onClick={() => toggleDescription(service.service_id)}
                                            sx={{ color: 'primary.main', fontWeight: 'bold', textTransform: 'none' }}
                                          >
                                            Voir moins
                                          </StyledButton>
                                        )}
                                      </>
                                    )}
                                  </Typography>

                                  {/* Voir dispos */}
                                  {service.hasAvailabilities ? (
                                    <StyledButton
                                      variant="contained"
                                      onClick={() => handleViewSlots(service)}
                                      sx={{
                                        backgroundColor: 'secondary.main',
                                        color: '#fff',
                                        '&:hover': { backgroundColor: '#555' },
                                        mt: 2,
                                        padding: '4px 10px',
                                        fontSize: '1rem',
                                        minWidth: '200px',
                                        borderRadius: '8px',
                                      }}
                                    >
                                      Voir les disponibilités
                                    </StyledButton>
                                  ) : (
                                    <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                                      Pas de disponibilités pour le moment.
                                    </Typography>
                                  )}

                                  {calendarOpenForService === service.service_id && (
                                    <Box sx={{ mt: 3 }}>
                                      <DatePickerComponent
                                        availableDates={availableDatesByService[service.service_id] || []}
                                        selectedDate={selectedDateByService[service.service_id] || ''}
                                        onDateSelect={(date) => handleDateSelect(date, service.service_id)}
                                      />
                                      {selectedDateByService[service.service_id] && (
                                        <TimePickerComponent
                                          availableTimes={availableTimesByService[service.service_id] || []}
                                          selectedTime={selectedTimeByService[service.service_id] || ''}
                                          onTimeSelect={(time) => handleTimeSelect(time, service.service_id)}
                                        />
                                      )}
                                    </Box>
                                  )}
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Box>
                  )}

                  {/* ======== Onglet Forfaits ======== */}
                  {currentTab === 'forfaits' && (
                    <Box sx={{ mt: 2 }}>
                      {loadingForfaits ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <CircularProgress />
                          <Typography>Chargement des forfaits...</Typography>
                        </Box>
                      ) : trainerForfaits.length === 0 ? (
                        <Typography variant="body1" sx={{ color: 'text.secondary', mt: 2 }}>
                          Le professionnel n'a pas encore créé de forfaits.
                        </Typography>
                      ) : (
                        <Grid container spacing={3}>
                          {trainerForfaits.map((forfait) => (
                            <Grid item xs={12} key={forfait.forfait_id}>
                              <Card
                                sx={{ p: 3, boxShadow: '0 2px 4px rgba(0,0,0,0.1)', borderRadius: '10px' }}
                              >
                                <CardContent>
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    sx={{ fontWeight: 'bold', color: 'text.primary' }}
                                  >
                                    {forfait.name} - {forfait.price} €
                                  </Typography>
                                  {forfait.description && (
                                    <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
                                      {forfait.description}
                                    </Typography>
                                  )}

                                  {/* Services inclus */}
                                  {forfait.services && forfait.services.length > 0 && (
                                    <Box sx={{ mb: 2 }}>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ fontWeight: 'bold', mb: 1, color: 'text.primary' }}
                                      >
                                        Services inclus :
                                      </Typography>
                                      {forfait.services.map((s, idx) => (
                                        <Typography key={idx} variant="body2" sx={{ ml: 2 }}>
                                          - {s.service_name} (x{s.quantity})
                                          {s.price ? ` (~ ${s.price}€)` : null}
                                        </Typography>
                                      ))}
                                    </Box>
                                  )}

                                  <StyledButton
                                    variant="contained"
                                    onClick={() => handlePurchaseForfait(forfait.forfait_id)}
                                    sx={{
                                      backgroundColor: '#5cb85c',
                                      color: '#fff',
                                      '&:hover': { backgroundColor: '#4cae4c' },
                                      mt: 2,
                                      fontSize: '1rem',
                                      minWidth: '200px',
                                      borderRadius: '8px',
                                    }}
                                  >
                                    Acheter ce forfait
                                  </StyledButton>
																	{trainer.cgv && isAuthenticated && (
																		<Typography variant="caption" sx={{ display: 'block', textAlign: 'center', mt: 1, color: 'text.secondary' }}>
											En cliquant sur ce bouton, vous acceptez les <span style={{ color: 'primary.main', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => setIsCGVModalOpen(true)}>Conditions Générales de Vente</span> du professionnel
										</Typography>
									)}
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Box>
                  )}
                </>
              )
            ) : (
              // Formateur non professionnel
              <Card
                sx={{
                  border: '2px solid',
                  borderColor: 'primary.main',
                  backgroundColor: 'background.paper',
                  borderRadius: '20px',
                  p: 3,
                  boxShadow: '0 6px 12px rgba(0,0,0,0.15)',
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ color: 'text.primary', fontSize: '1.6rem', fontWeight: 'bold' }}
                  >
                    Vous êtes ce professionnel ?
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary', mt: 2 }}>
                    Contactez-nous pour rejoindre Rintintin et proposer vos services.
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => navigate('/contact')}
                    sx={{
                      mt: 3,
                      backgroundColor: 'primary.main',
                      color: '#333',
                      textTransform: 'none',
                      '&:hover': { backgroundColor: '#e0b127' },
                      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                      borderRadius: '8px',
                    }}
                  >
                    Nous contacter
                  </Button>
                </CardContent>
              </Card>
            )}
          </Grid>

          {/* =========== Colonne de droite (carte de réservation) =========== */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              position: 'sticky',
              top: '80px',
              alignSelf: 'flex-start',
            }}
          >
            {/* Seulement s'il est pro et qu'on est sur l'onglet Services (ou qu'il n'y a qu'un bloc Services) */}
            {trainer.is_professional && (
              <Card
                ref={reservationCardRef}
                sx={{
                  border: '2px solid',
                  borderColor: 'primary.main',
                  backgroundColor: 'background.paper',
                  borderRadius: '20px',
                  p: 3,
                  boxShadow: '0 6px 12px rgba(0,0,0,0.15)',
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ color: 'text.primary', fontSize: '1.6rem', fontWeight: 'bold' }}
                  >
                    Réserver un service
                  </Typography>

                  {!isAuthenticated ? (
                    <Box>
                      <Typography variant="body1" gutterBottom sx={{ color: 'text.secondary' }}>
                        Vous devez être connecté pour réserver un service.
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() => navigate('/login', { state: { from: location.pathname } })}
                        sx={{
                          mt: 2,
                          backgroundColor: 'primary.main',
                          textTransform: 'none',
                          color: '#333',
                          '&:hover': { backgroundColor: '#e0b127' },
                          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                          borderRadius: '8px',
                        }}
                      >
                        Se connecter
                      </Button>
                    </Box>
                  ) : (
                    <Box>
                      <Box sx={{ mt: 2, mb: 3 }}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </Box>

                      {selectedService && (
                        <Box sx={{ mt: 2 }}>
                          <Typography variant="body1" sx={{ color: 'text.secondary', mb: 1 }}>
                            <strong>Service :</strong> {selectedService.service_name}
                          </Typography>
                          {selectedDateByService[selectedService.service_id] && (
                            <Typography variant="body1" sx={{ color: 'text.secondary', mb: 1 }}>
                              <strong>Date :</strong>{' '}
                              {dayjs(selectedDateByService[selectedService.service_id])
                                .locale('fr')
                                .format('dddd D MMMM')}
                            </Typography>
                          )}
                          {selectedTimeByService[selectedService.service_id] && (
                            <Typography variant="body1" sx={{ color: 'text.secondary', mb: 1 }}>
                              <strong>Heure :</strong>{' '}
                              {dayjs(selectedTimeByService[selectedService.service_id], ['HH:mm:ss', 'HH:mm']).format(
                                'HH:mm'
                              )}
                            </Typography>
                          )}

                          {selectedService.allow_online_payment ? (
                            <Typography variant="body2" sx={{ color: 'success.main', mt: 1 }}>
                              <MonetizationOnIcon
                                style={{
                                  verticalAlign: 'middle',
                                  marginRight: '5px',
                                  color: '#5cb85c',
                                }}
                              />
                              Paiement en ligne
                            </Typography>
                          ) : (
                            <Typography variant="body2" sx={{ color: 'warning.main', mt: 1 }}>
                              <MonetizationOnIcon
                                style={{
                                  verticalAlign: 'middle',
                                  marginRight: '5px',
                                  color: '#f0ad4e',
                                }}
                              />
                              Paiement sur place
                            </Typography>
                          )}

                          {/* Forfait dispo ? */}
                          {selectedService.hasForfaitDisponible && (
                            <Box
                              sx={{
                                mt: 2,
                                p: 2,
                                border: '2px dashed #5cb85c',
                                backgroundColor: '#f6fff6',
                                borderRadius: '8px',
                              }}
                            >
                              <Typography variant="body2" sx={{ color: '#4cae4c', fontWeight: 'bold' }}>
                                Vous possédez un forfait qui couvre ce service !
                              </Typography>
                              <Typography variant="body2" sx={{ mt: 1 }}>
                                À la validation, vous pourrez l'utiliser pour éviter un paiement supplémentaire.
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      )}

                      {!selectedService && (
                        <Typography variant="body1" sx={{ color: '#777', mt: 2 }}>
                          Sélectionnez un service, une date et une heure pour continuer.
                        </Typography>
                      )}

                      {/* Choix du chien */}
                      {dogs.length > 0 ? (
                        <FormControl fullWidth sx={{ mt: 2 }}>
                          <InputLabel id="dog-select-label">Choisir un chien</InputLabel>
                          <Select
                            labelId="dog-select-label"
                            value={selectedDog !== null ? selectedDog.toString() : ''}
                            onChange={handleDogSelect}
                            label="Choisir un chien"
                          >
                            {dogs.map((dog) => (
                              <MenuItem key={dog.dogId} value={dog.dogId.toString()}>
                                {dog.name} - {dog.breed}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                          Vous n'avez pas encore ajouté de chien à votre compte.
                        </Typography>
                      )}

                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button
                          variant="contained"
                          onClick={openAddDogModal}
                          sx={{
                            backgroundColor: 'secondary.main',
                            color: '#fff',
                            textTransform: 'none',
                            marginBottom: 2,
                            '&:hover': { backgroundColor: '#555' },
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            borderRadius: '8px',
                          }}
                        >
                          Ajouter un chien
                        </Button>
                      </Box>

                      {/* Question service */}
                      {selectedService && selectedService.question && (
                        <Box sx={{ mt: 3 }}>
                          <Typography variant="body1" gutterBottom sx={{ color: 'text.primary' }}>
                            {selectedService.question}
                          </Typography>
                          <TextField
                            label="Votre réponse"
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            value={clientAnswer}
                            onChange={(e) => setClientAnswer(e.target.value)}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': { borderColor: '#ddd' },
                                '&:hover fieldset': { borderColor: 'primary.main' },
                                '&.Mui-focused fieldset': { borderColor: 'primary.main' },
                              },
                            }}
                          />
                        </Box>
                      )}

                      {/* Bouton final */}
                      <StyledButton
                        variant="contained"
                        onClick={handleConfirmReservation}
                        disabled={
                          isReserving ||
                          !selectedService ||
                          !selectedDateByService[selectedService?.service_id || 0] ||
                          !selectedTimeByService[selectedService?.service_id || 0] ||
                          !selectedDog
                        }
                        sx={{
                          mt: 3,
                          backgroundColor: 'primary.main',
                          color: '#333',
                          '&:hover': { backgroundColor: '#e0b127' },
                          width: '100%',
                          boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
                          fontWeight: 'bold',
                          padding: '12px 0',
                          borderRadius: '8px',
                        }}
                      >
                        {isReserving ? (
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <CircularProgress size={20} sx={{ color: '#fff' }} />
                            <span>Chargement...</span>
                          </Box>
                        ) : (
                          'Confirmer la réservation'
                        )}
                      </StyledButton>
                    </Box>
                  )}
                  {trainer.cgv && isAuthenticated && (
                    <Typography variant="caption" sx={{ display: 'block', textAlign: 'center', mt: 1, color: 'text.secondary' }}>
                      En cliquant sur ce bouton, vous acceptez les <span style={{ color: 'primary.main', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => setIsCGVModalOpen(true)}>Conditions Générales de Vente</span> du professionnel
                    </Typography>
                  )}
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>

        {/* Modal ajout de chien */}
        <AddDogModal
          isOpen={isAddDogModalOpen}
          onRequestClose={closeAddDogModal}
          onDogAdded={(newDog) => {
            setDogs([...dogs, newDog]);
            closeAddDogModal();
          }}
        />
        <CGVModal
          isOpen={isCGVModalOpen}
          onClose={() => setIsCGVModalOpen(false)}
          cgvContent={trainer.cgv || ''}
        />
        <ToastContainer />
      </Box>
    </ThemeProvider>
  );
};

export default TrainerDetail;
