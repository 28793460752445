// src/pages/InvitationSignupPage.tsx
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Alert,
} from '@mui/material';

const InvitationSignupPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Récupération du token d'invitation depuis l'URL
  const [invitationToken, setInvitationToken] = useState<string | null>(null);

  // Champs du formulaire
  const [name, setName] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('invitation_token');
    if (token) {
      setInvitationToken(token);
    } else {
      setError("Token d'invitation introuvable dans l'URL.");
    }
  }, [location.search]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!invitationToken) {
      setError("Aucun token d'invitation.");
      return;
    }
    if (password !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      // Appeler l’endpoint finalize-invited-user pour finaliser le compte
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/finalize-invited-user`,
        {
          invitation_token: invitationToken,
          name,
          lastname,
          phone,
          password,
        }
      );
      if (response.status === 200) {
        setSuccess("Compte finalisé ! Vous allez être redirigé vers vos réservations.");
        // Redirection après quelques secondes
        setTimeout(() => {
          navigate('/reservations');
        }, 2000);
      } else {
        setError("Une erreur est survenue lors de la finalisation du compte.");
      }
    } catch (err: any) {
      console.error("Erreur finalize user:", err);
      setError(err.response?.data?.error || "Erreur de finalisation.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 8 }}>
        <Card sx={{ p: 3 }}>
          <CardContent>
            <Typography variant="h4" gutterBottom align="center">
              Finaliser mon compte
            </Typography>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            {success && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {success}
              </Alert>
            )}
            {!invitationToken ? (
              <Typography variant="body1" align="center">
                Token non valide ou absent.
              </Typography>
            ) : (
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  label="Prénom"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                  required
                  margin="normal"
                />
                <TextField
                  label="Nom"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  fullWidth
                  required
                  margin="normal"
                />
                <TextField
                  label="Téléphone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Mot de passe"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  required
                  margin="normal"
                />
                <TextField
                  label="Confirmer le mot de passe"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  fullWidth
                  required
                  margin="normal"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  sx={{ mt: 2, py: 1.5 }}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Valider"
                  )}
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default InvitationSignupPage;
